import { useParams } from "react-router-dom";
import { Card } from "..";
import { blogPages } from "../BlogData";

import "./BlogPage.scss";

export default function BlogPage() {
  const params = useParams();
  const data = blogPages.find((x) => x.id === params.id);
  const filterData = blogPages.filter((x) => x.id !== params.id).slice(0, 2);

  return (
    <div className="blogPage main">
      <div className="blogPage__body">
        <img src={data.img} className="blogPage__image" />
        <h1 className="blogPage__title m-4">{data.title}</h1>
        <div className="blogPage__body">{data.body}</div>
      </div>
      <h2 className="blogPage__explore">Check out our other blogs</h2>{" "}
      <div className="small__wrapper">
        {filterData.map(({ id, img, title, description }) => (
          <Card id={id} img={img} title={title} description={description} />
        ))}
      </div>
    </div>
  );
}
