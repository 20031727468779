import React, { useRef } from 'react'
import useOnScreen from "../../Hooks/useOnScreen"
import './infocomp.scss';

// information cards found at the top of the "our story" page

export default function InfoComp({ title, body }) {
    const ref = useRef(null)
    return (
        <div ref={ref} className={`infocomp-section ${useOnScreen(ref) ? "fade-in" : ""}`}>
            {/* Underline below each section */}
            <hr className='info-brow' />
            <article>
                <h1>{title}</h1>
                <p>{body}</p>
            </article>
        </div>
    )
}