import React from "react";
import "./BlogCarouselSlide.scss";

export default function BlogCarouselSlide({ image, title, description, link }) {
  return (
    <div className="BlogCarouselSlide__body">
      <img src={image} alt={title} className="BlogCarouselSlide__image" />
      <h2 className="BlogCarouselSlide__title">{title}</h2>
      <p className="BlogCarouselSlide__description">{description}</p>
      <a href={link} className="BlogCarouselSlide__link">
        Read More
      </a>
    </div>
  );
}
