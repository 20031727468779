import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import SinaiLogo from "../../assets/SinaiLogo/sinai-logo.svg";
import { useLocation } from "react-router-dom";
import "./style.scss";

export default function NavBar() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleCollapse = () => {
    var nav = document.getElementById("navbarSupportedContent");
    var btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  };

  return (
    <nav
      className={`container-fluid navbar sticky-top navbar-expand-lg navbar-light p-3 ${
        scrolled ? "navbar--scroll" : ""
      }`}
    >
      <NavLink
        className="navbar-brand nav-link p-0 navbar__logo"
        aria-current="page"
        to="/"
        onClick={handleCollapse}
      >
        <img className="" src={SinaiLogo} alt="Sinai Logo" />
      </NavLink>
      <button
        id="navbarBtn"
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto my-3">
          {/* About us */}
          <li className="nav-item">
            <NavLink
              className="nav-link"
              aria-current="page"
              to="/"
              onClick={handleCollapse}
            >
              <span className="navbar__text">HOME</span>
            </NavLink>
          </li>
          <li className="nav-item dropdown">
            <button
              className="nav-link"
              id="navbarDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="navbar__text">ABOUT</span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <NavLink
                  className="dropdown-item"
                  to="/about/mission-and-philosophy"
                  onClick={handleCollapse}
                >
                  MISSION AND PHILOSOPHY
                </NavLink>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <NavLink
                  className="dropdown-item"
                  to="/about/leadership"
                  onClick={handleCollapse}
                >
                  LEADERSHIP
                </NavLink>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <NavLink
                  className="dropdown-item"
                  to="/about/partners"
                  onClick={handleCollapse}
                >
                  PARTNERS
                </NavLink>
              </li>
              {/* <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <NavLink
                  className="dropdown-item"
                  to="/about/mission-and-philosophy"
                  onClick={handleCollapse}
                >
                  MISSION
                </NavLink>
              </li> */}
            </ul>
          </li>

          {/* Work */}
          <li className="nav-item dropdown">
            <button
              className="nav-link"
              id="navbarDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="navbar__text">OUR WORK</span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <NavLink
                  className="dropdown-item"
                  to="/our-work/projects"
                  onClick={handleCollapse}
                >
                  PROJECTS
                </NavLink>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <NavLink
                  className="dropdown-item"
                  to="/our-work/initiatives"
                  onClick={handleCollapse}
                >
                  INITIATIVES
                </NavLink>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <NavLink
                  className="dropdown-item"
                  to="/our-work/summer-student-program"
                  onClick={handleCollapse}
                >
                  SUMMER STUDENT PROGRAM
                </NavLink>
              </li>
            </ul>
          </li>

          {/* Services */}
          <li className="nav-item dropdown">
            <button
              className="nav-link"
              id="navbarDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="navbar__text">OUR SERVICES</span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <NavLink
                  className="dropdown-item"
                  to="/our-services/classes"
                  onClick={handleCollapse}
                >
                  OFFERED CLASSES
                </NavLink>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <NavLink
                  className="dropdown-item"
                  to="/our-services/tech-services"
                  onClick={handleCollapse}
                >
                  TECH SERVICES
                </NavLink>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <NavLink
                  className="dropdown-item"
                  onClick={handleCollapse}
                  to="/our-services/food-programs"
                >
                  FOOD PROGRAMS
                </NavLink>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <NavLink
                  className="dropdown-item"
                  onClick={handleCollapse}
                  to="/our-services/health-and-safety-support"
                >
                  HEALTH & SAFETY SUPPORT
                </NavLink>
              </li>
            </ul>
          </li>

          {/* Involvement pages */}
          <li className="nav-item dropdown">
            <button
              className="nav-link"
              id="navbarDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="navbar__text">JOIN US</span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <NavLink
                  className="dropdown-item"
                  to="/join-us/member"
                  onClick={handleCollapse}
                >
                  BE A MEMBER
                </NavLink>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <NavLink
                  className="dropdown-item"
                  to="/join-us/involved"
                  onClick={handleCollapse}
                >
                  BE INVOLVED
                </NavLink>
              </li>
            </ul>
          </li>

          {/* Blog; temporarily disabled since unused */}
          <li className="nav-item">
            <NavLink
              className="nav-link"
              aria-current="page"
              to="/communication"
              onClick={handleCollapse}
            >
              <span className="navbar__text">COMMUNICATIONS</span>
            </NavLink>
          </li>

          {/* Contact section */}
          <li className="nav-item">
            <NavLink
              className="nav-link"
              aria-current="page"
              to="/contact"
              onClick={handleCollapse}
            >
              <span className="navbar__text">CONTACT</span>
            </NavLink>
          </li>
        </ul>

        {/* Donate button */}
        <div className={`nav__donate ${scrolled ? "nav__donate--scroll" : ""}`}>
          <NavLink
            className={`nav__donate-text ${
              scrolled ? "nav__donate-text--scroll" : ""
            }`}
            to="/donate"
            onClick={handleCollapse}
          >
            DONATE
          </NavLink>
        </div>
      </div>
    </nav>
  );
}
