import React, { useEffect, useState } from "react";
import "./SideNav.scss";

// side nav found on left side of the projects page

export default function SideNav({ children }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const clickHandler = () => {
        setIsExpanded(prevVal => !prevVal);
    };

    return (
        <div className={`side-nav ${isExpanded ? "side-nav--expand" : ""}`}>
            {/* button to expand/collapse the sidenav. Only visible on screens < 768px */}
            <button
                className={`side-nav__button ${isExpanded ? "side-nav__button--expand" : ""}`}
                type="button"
                onClick={clickHandler}
            >
                <i className={`fas fa-chevron-${isExpanded ? "left" : "right"}`} />
            </button>
            <div className='side-nav__content'>
                {children}
            </div>
        </div>
    );
}