import React from "react";
import "./OurCommitment.scss";

// commitment section on home page

export default function OurCommitment() {
  return (
    <div className="commitment">
      <h2 className="pt-4 pb-4 commitment-title fw-bold">
        Better Skills, Better Well-beings
      </h2>
      <p className="py-1">
        At Sinai Foundation Canada, we collaborate with global partners to
        empower every individual's journey to education, employment, and
        well-being. Since 2019, we've been a spark for community-driven change,
        tackling critical needs like education, health, and overall well-being.
        From our backyard in Canada to communities worldwide, we're making the
        change that matters, turning challenges into opportunities and creating
        sustainable impact.
      </p>
    </div>
  );
}
