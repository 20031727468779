import React from "react";
import HeroBanner from "../../../components/HeroBanner";
import PaymentForm from "../../../components/PaymentForm";

// Membership page

export default function Index() {
  return (
    <main>
      <HeroBanner title="Become A Member" />
      <div className="main">
          <h1 className="header">Membership Benefits</h1>
          <h2 className="subheader">
            Become a friend of Sinai Foundation Canada - No One Should be Left Hungry
          </h2>

          <div className="mt-3 ms-2">
            <ul>
              <li>
                All funds will be used towards the welfare of seniors, single
                parents, children, and emergency services for the areas struck by
                disaster or war zones
              </li>
              <li>
                A member is part of the projects that makes difference in the
                communities
              </li>
              <li>
                A member will get 15% discount on all training and courses offered
              </li>
              <li>
                An active one-year member will be eligible to become a General
                Body Member (GBM)
              </li>
              <li>A GBM will be eligible to cast their vote</li>
              <li>
                An active one-year GBM will be eligible to contest for the Board
                of Governors (BOG)
              </li>
              <li>
                An elected BOG member will be eligible to contest for office
                positions including Vice President, Secretary General, etc.
              </li>
              <li>
                An organization will be eligible to advertise their business on
                any event hosted by SFC
              </li>
              <li>
                The logo of the organization will be displayed on SFC website as
                partners
              </li>
            </ul>

            <h3>Membership types:</h3>
            <ul>
              <li>Associate Member: $20/year</li>
              <li>GBM Member: $60/year</li>
              <li>Organization: $100/year</li>
            </ul>
          </div>
          <p className="mb-5">
            You may revoke your authorization at any time, subject to providing notice
            of 30 days. To obtain a cancellation form, or for more
            information on your right to cancel a PAD agreement, contact
            our financial team or visit www.cdnpay.ca.
          </p>
          <PaymentForm />
        </div>
     
    </main>
  );
}