import React from "react";
import "./GoalsCard.scss";

export default function GoalsCard({
  header,
  subheader,
  img,
  color,
  className,
  fullImage,
}) {
  return (
    <div
      className={`goals-card ${className}`}
      style={{ backgroundColor: color }}
    >
      {!fullImage && (
        <>
          <h1 className="goals-card__header">{header}</h1>
          <h2 className="goals-card__header">{subheader}</h2>
        </>
      )}
      <div className={`goal-img ${fullImage ? "goal-img--full" : ""}`}>
        {img}
      </div>
    </div>
  );
}
