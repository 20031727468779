import React from "react";
import Fact from "./Fact/Fact";

// Foundation facts found on home page

export default function Facts() {
  return (
    <div className="container mt-5 mb-5">
      <div className="fact-body">
        <div className="row text-center">
          <div className="col-12 col-lg-3 col-md-6 col-sm-6">
            <Fact factNumber={230} factTitle="Families being helped" />
          </div>
          <div className="col-12 col-lg-3 col-md-6 col-sm-6">
            <Fact factNumber={6} factTitle="Cities covered" />
          </div>
          <div className="col-12 col-lg-3 col-md-6 col-sm-6">
            <Fact factNumber={15} factTitle="Volunteers" />
          </div>
          <div className="col">
            <Fact factNumber={5} factTitle="Different programs" />
          </div>
        </div>
      </div>
    </div>
  );
}
