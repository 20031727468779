import React, { useEffect, useState } from "react";
import SideLink from "./SideLink/SideLink";
import useViewPort from "../../Hooks/useViewPort";
import "./SideMedia.scss";

// Media bubble links that are on the right side of the screen

export default function Index() {
  const [isexpand, setIsexpand] = useState(true);

  const { width } = useViewPort();

  useEffect(() => {
    // auto hide the side panel if the screen shrinks too small.
    if (width < 768) setIsexpand(false);
    // only expand the panel if the panel is already expanded
    else if (isexpand) setIsexpand(true);
  }, [width]);

  const clickHandler = () => {
    setIsexpand((prevVal) => !prevVal);
  };

  return (
    <div className={`side-media ${!isexpand ? "side-media--hide" : ""}`}>
      {/* Button to control the expanding/collapsing */}
      <button
        className={`side-media__button ${
          isexpand ? "side-media__button--hide" : ""
        }`}
        type="button"
        onClick={clickHandler}
      >
        <i className={`fas fa-chevron-${isexpand ? "right" : "left"}`} />
      </button>
      {/* List of media links */}
      <ul
        className={`side-media__list ${
          isexpand ? "" : "side-media__list--hide"
        }`}
      >
        <li className="side-media__item">
          <SideLink
            name="Facebook"
            href="https://www.facebook.com/SinaiFoundationCanada"
            iconName="facebook-f"
          />
        </li>
        <li className="side-media__item">
          <SideLink
            name="Twitter"
            href="https://twitter.com/CanSinai"
            iconName="twitter"
          />
        </li>
        <li className="side-media__item">
          <SideLink
            name="Instagram"
            href="https://www.instagram.com/sinaifoundationcanada/"
            iconName="instagram"
          />
        </li>
        <li className="side-media__item">
          <SideLink
            name="Youtube"
            href="https://www.youtube.com/channel/UCYPqwExTszPfwFRhW4r_xzg/featured"
            iconName="youtube"
          />
        </li>
        <li className="side-media__item">
          <SideLink
            name="Linkedin"
            href="https://www.linkedin.com/in/sinaifoundationcanada-769495213/"
            iconName="linkedin"
          />
        </li>
      </ul>
    </div>
  );
}
