import React from "react";

import { Link, useRouteMatch } from "react-router-dom";
import HeroBanner from "../../../components/HeroBanner";
import { descriptions } from "../descriptions";

// Root page when url only contains /projects

export default function Root() {
  const match = useRouteMatch().url;
  // URLs of the featured projects
  const featuredLinks = [
    "Mental-Health-Project",
    "Raised-Funds-for-Ethiopia-crisis-during-Ramadan-2021",
    "Food-Distribution-in-Mississauga",
  ];

  return (
    <main>
      <HeroBanner
        title="Our Projects"
        body="Sinai Foundation is committed to providing help to those who need it because helping others is the most blessed part of our life"
      />
      <div className="our-projects main" id="projects-root-page">
        {/* Featured projects section */}
        <h1 className="header text-center">Featured Projects</h1>
        <div className="preview-grid mt-4">
          {featuredLinks.map((url) => (
            <div key={url} className="preview-card">
              <div className="body">
                <h4>{descriptions[url].title}</h4>
                <span>{descriptions[url].bottom}</span>
              </div>
              <Link to={`${match}/${url}`} className="button-style">
                Read More
              </Link>
            </div>
          ))}
        </div>
        {/* Full list of projects */}
        <div className="full-project-list my-2">
          <h1 className="subheader">Find all of our other projects here!</h1>
          <div className="project-entry-field">
            {Object.keys(descriptions).map((path) => (
              <Link className="external-link" to={`${match}/${path}`}>
                <div className="project-entry lead">
                  {descriptions[path].title}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}
