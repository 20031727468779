// this file is specifially for containing data of the board members so the index.js page is not so cluttered

// image imports
import AhmadImg from "../../../assets//Headshots/Ahmad_Khan.jpeg";
import KhurshidKhan from "../../../assets//Headshots/Khurshid_Khan.jpeg";
import AwaisKhan from "../../../assets//Headshots/Awais_Khan.jpeg";
// import Mishay from "../../../assets//Headshots/Mishay.jpeg";
import Azmat from "../../../assets//Headshots/Azmat.jpeg";
import WaheedKhan from "../../../assets//Headshots/Waheed_Khan.jpeg";
import QuratUlAin from "../../../assets//Headshots/Qurat_Ul_Ain.jpeg";
import FasilMalik from "../../../assets/Headshots/Fasil_Malik.jpeg";
import Mobin from "../../../assets/Headshots/Mobin.jpg";
import Ibrahim from "../../../assets/Headshots/Ibrahim.jpg";
import Bassam from "../../../assets/Headshots/Bassam.jpg";
import Aisha from "../../../assets/Headshots/Aisha.jpeg";
import AbdulAzeez from "../../../assets/Headshots/Abdul_Azeez.jpg";
import Rebecca from "../../../assets/Headshots/Rebecca.jpeg";

// exported data of each member
export const data = [
  {
    image: AhmadImg,
    title: "Founder",
    name: "Ahmad Khan",
    info: `Ahmad Khan is the Founder of Sinai Foundation Canada, established in 2019, serving Sinai Foundation Canada and other Non-profits.
    He has a extensive experience of working with international NGOs including Red Cross Canada. 
    With Red Cross Canada, he served as a volunteer at the designation of Emergency Responder. 
    Being an Emergency Responder, he assisted people who were under stress, frustration, and emergency conditions. 
    Along with Red Cross Canada, Ahmad Khan is also an effective member of a community in GTA. 
    In GTA he provided his services at election campaigns, religious places and other important community activities.`,
  },
  {
    image: Mobin,
    title: "IT Manager",
    name: "Mobin Akhter",
    info: `Mobin Akhter is a graduate of Sheridan College with a strong foundation in web development and database administration. With experience in both front-end and back-end technologies, Mobin excels in creating efficient, responsive web applications and managing complex databases.`,
  },
  {
    image: Ibrahim,
    title: "Events manager",
    name: "Ibrahim",
    // info: `Qurat Ul Ain has over 14 years of Business experience in boutique and Garment industry.
    // She has served in senior financial positions with several emerging companies in which she played a key role in fundraising and the transition to executing growth strategies`,
  },
  {
    image: Bassam,
    title: "Marketing lead",
    name: "Bassam Abid",
    // info: `Mrs Mishay Ailiyaa is a young enthusiastic professional and social worker having roots in Kenya and Pakistan.
    // Mrs Mishay Ailiyaa has worked for FMK as Director.
    // FMK is a scientific and Medical equipment company dealing in new and refurbished equipment’s worldwide to serve humanity.`,
  },

  {
    image: Aisha,
    title: "Funding/ Grant and communication Manager",
    name: "Aisha Nawaz",
    // info: `Mrs Mishay Ailiyaa is a young enthusiastic professional and social worker having roots in Kenya and Pakistan.
    // Mrs Mishay Ailiyaa has worked for FMK as Director.
    // FMK is a scientific and Medical equipment company dealing in new and refurbished equipment’s worldwide to serve humanity.`,
  },
  {
    image: Rebecca,
    title: "Social service manager",
    name: "Rebecca",
    // info: `Mrs Mishay Ailiyaa is a young enthusiastic professional and social worker having roots in Kenya and Pakistan.
    // Mrs Mishay Ailiyaa has worked for FMK as Director.
    // FMK is a scientific and Medical equipment company dealing in new and refurbished equipment’s worldwide to serve humanity.`,
  },

  {
    image: AbdulAzeez,
    title: "Data Analyst (IT team)",
    name: "Abdulazeez",
    info: `Abdulazeez Saka is a Data Analyst with the Sinai Foundation Canada, where they leverage data and analytics to drive the organization's impact. With a background in statistics and over 4 years of experience as a System Analyst for a security outfit, he brings a strong analytical skillset and deep understanding of data-driven decision-making to their role. In this position, he is passionate about using data-driven insights to support the foundation's initiatives in promoting intercultural understanding, education, and humanitarian aid across Canada. Abdulazeez Saka works closely with the program teams to collect, analyze, and interpret data that informs the foundation's strategic planning and helps measure the effectiveness of its various initiatives.`,
  },

  {
    image: FasilMalik,
    title: "Direction Education Planning",
    name: "Malik Faisal Aslam",
    info: `Malik Faisal Aslam has been associated with Sinai Foundation since 2019 
  and has been involved in different educational, strategic and staff training programs`,
  },

  {
    image: KhurshidKhan,
    title: "Managing Director of Finance",
    name: "Khurshid",
    info: `Khurshid comes from a Computer Science background at a renowned university and is among the students at the top of her class. 
  She came to Canada as an immigrant along with her husband and started a new journey of their life in an ambitious and planned manner.`,
  },

  {
    image: Azmat,
    title: "Managing Director HR",
    name: "Azmat Shah",
    info: `Azmat Shah holds three masters degrees in Agriculture and an Associate degree in Computer Information System. 
  He is actively participating in different social and welfare activities for uplifting the life standards of the people in our community.`,
  },

  {
    image: undefined,
    title: "Managing Director",
    name: "Roman Khan",
    info: `Roman graduated in Science and Nursing from McMaster University, Canada. He worked as a technician at Canada Home Services. 
    At his job, he was assigned to assess, provide, and manage installation of breathing aid equipment for patients suffering with lung and breathing disorders. 
    Roman also helped in the Physiotherapy Clinic with staff and vendors.`,
  },

  {
    image: AwaisKhan,
    title: "Managing Director of Marketing",
    name: "Awais Khan",
    info: `Awais is graduated from University of Gulph Ontario with undergraduate degree in life science and diploma in Business. 
  He has been brilliant and practical during his studies and has gained a lot of knowledge.`,
  },

  {
    image: undefined,
    title: "Managing Director Events Coordinations",
    name: "Sahilah Shah",
    info: `Sahilah has a vast experience of organizing events of various kinds and has operated numerous programs of various niches. 
    He is highly qualified in the management field and is very skilled at knowing to how to plan, organize, and run a successful event.`,
  },
];
