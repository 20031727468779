import React from 'react';
import './SideLink.scss';

// Individual side link

export default function index({name, href, iconName}) {
    return (
        <a aria-label={name} name={name} title={name} href={href} className={`side-link side-link--${name}`} target="_blank" rel="noreferrer">
            {/* <span className='side-link__title'>{name}</span> */}
            <i className={`fab fa-${iconName} side-link__icon side-link__icon--${name}`} aria-hidden="true" />
        </a>
    );
}