import React from "react";
import "./ContactCards.scss";

// Contact cards found in the contact us section. Are not the same as the cards in the footer, those are found in the footer component.

function ContactCards(props) {
  return (
    <div className="contact-cards bg-light">
      <div className="contact-cards__card">
        <h2 className="contact-cards__subheader primary-color">Location</h2>
        <i className="fas fa-map-marker-alt fa-1x primary-color contact-cards__icon" />
        <a
          aria-label="Address"
          name="address"
          href="https://goo.gl/maps/TykykvmtxgpTxdYD7"
          target="_blank"
          rel="noreferrer"
          className="contact-cards__text"
        >
          5218 Fallingbrook Drive
          <br /> Mississauga, ON
          <br /> Canada
        </a>
      </div>
      <div className="contact-cards__card contact-cards__card--middle">
        <h2 className="contact-cards__subheader primary-color">Phone Number</h2>
        <i className="fas fa-phone fa-1x primary-color contact-cards__icon" />
        <a
          aria-label="Phone"
          name="phone"
          href="tel:+18339051260"
          className="contact-cards__text"
        >
          +1 (833) 905-1260
        </a>
      </div>
      <div className="contact-cards__card">
        <h2 className="contact-cards__subheader primary-color">Email</h2>
        <i className="fas fa-envelope fa-1x primary-color contact-cards__icon" />
        <a
          className="contact-cards__text"
          aria-label="Email"
          name="email"
          href="mailto:info@sinaifoundation.ca"
          target="_blank"
          rel="noreferrer"
        >
          info@sinaifoundation.ca
        </a>
      </div>
    </div>
  );
}

export default ContactCards;
