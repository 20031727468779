import React, { useState } from "react";
import HeroBanner from "../../../components/HeroBanner";
import ClassCard from "../../../components/ClassCard";
import {
  Form,
  Input,
  Select,
  Submit,
  SubmitHandler,
} from "../../../components/Form/index";
import FormModal from "../../../components/FormModal";

export default function Index() {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const onSubmit = ({ name, email, phone, course }) => {
    SubmitHandler({
      text: `Name: ${name}\nEmail: ${email}\nPhone: ${phone}\nCourse: ${course}`,
      subject: "Course Registration",
      // info: {
      //     coll: 'students',
      //     doc: `${name}`,
      //     data: {
      //         email: `${email}`,
      //         phone: `${phone}`,
      //         course: `${course}`
      //     }
      // },
      setError: setError,
      setLoading: setLoading,
    });
    setShowModal(true);
  };

  return (
    <div>
      <FormModal
        showModal={showModal}
        setShowModal={setShowModal}
        text="Your course registration has been submited."
        error={error}
        setError={setError}
        loading={loading}
        setLoading={setLoading}
      />
      <HeroBanner
        title="Our virtual classes"
        body="Sinai Foundation Canada focuses on the betterment of each community. 
          We want to make sure that we can provide tools that will help others build skills and competencies that they can apply to the real life world. 
          Join today to make sure that you are not missing out!"
      />
      <div className="container-fluid main">
        <div className="row">
          {/* <ClassCard
            title="First Aid Training"
            location="Mississauga, ON"
            time="12:30 - 1:30 pm"
            instructorName=""
            date="July 2, 2022"
          /> */}
        </div>
        <div
          className="shadow card-style p-4 mx-auto mt-5 mb-5"
          style={{ maxWidth: "50rem" }}
        >
          <h2 className="subheader primary-color short-underline mb-4 mx-auto">
            Register Now!
          </h2>
          <Form onSubmit={onSubmit}>
            <Input
              className="col-12"
              name="name"
              placeholder="Name"
              required={{
                required: "Please Enter Your Name",
                pattern: {
                  value: /^(?!\s*$).+/,
                  message: "Please Enter Your Name",
                },
              }}
            />
            <Input
              className="col-12"
              name="email"
              placeholder="Email"
              required={{
                required: "Please Enter A Valid Email Address",
                pattern: {
                  // eslint-disable-next-line no-useless-escape
                  value:
                    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                  message: "Please Enter A Valid Email Address",
                },
              }}
            />
            <Input
              className="col-12"
              type="tel"
              name="phone"
              placeholder="Phone Number"
              required={{
                required: "Please Enter A Valid Phone Number",
                maxLength: 10,
                pattern: {
                  value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                  message: "Please Enter A Valid Phone Number",
                },
              }}
            />
            <Select
              className="col-12"
              name="course"
              placeholder="Course"
              defaultOption=""
              options={[]}
              // options={["First Aid Training", "Mental Health Webinar"]}
              required={{
                required: "Please Select A Course",
              }}
            />
            <Submit position="middle" text="Send" />
          </Form>
        </div>
      </div>
    </div>
  );
}
