import React, { useRef } from "react";
import AliceCarousel from "react-alice-carousel";
import PartnerCard from "../../components/PartnerCard/";

// image imports
import UNHCR from "../../assets//Logos/UNHCRLogo2.png";
import OON from "../../assets//Logos/onn-logo.png";
import Rotary from "../../assets//Logos/rotary-logo.png";
import MWC from "../../assets//Logos/mwc-logo.png";
import RedCross from "../../assets//Logos/crc-logo.png";
import Spark from "../../assets//Logos/spark-logo.png";
import Ontario from "../../assets//Logos/ontario-gov-logo.png";
import Inara from "../../assets//Logos/inara-logo.png";
import Lions from "../../assets//Logos/lions-logo.png";
import ESDC from "../../assets//Logos/ESDC.png";
import QuickWorks from "../../assets//Logos/quick-works-logo.png";
import Mawrid from "../../assets//Logos/4.png";

import "react-alice-carousel/lib/alice-carousel.css";
import "./PartnerCarousel.scss";

// partner carousel found on the bottom of the home page

export default function PartnerCarousel() {
  const responsive = {
    0: {
      items: 2,
    },
    767: {
      items: 3,
    },
    1279: {
      items: 4,
    },
  };

  const ref = useRef();

  return (
    <div className="partner-carousel">
      {/* Carousel controls (ie. buttons) */}
      <button
        className="partner-carousel__btn partner-carousel__btn--left"
        onClick={() => ref.current.slidePrev()}
      >
        <i className="partner-carousel__icon fas fa-chevron-left" />
      </button>
      <button
        className="partner-carousel__btn partner-carousel__btn--right"
        onClick={() => ref.current.slideNext()}
      >
        <i className="partner-carousel__icon fas fa-chevron-right" />
      </button>

      {/* The carousel containing the icons */}
      <AliceCarousel
        mouseTracking
        disableButtonsControls
        disableDotsControls
        startIndex={1}
        infinite
        responsive={responsive}
        ref={ref}
      >
        <div className="partner-carousel__item">
          <PartnerCard
            height="4.5rem"
            altText="Rotary"
            logo={Rotary}
            link="https://www.rotary.org/en"
          />
        </div>
        <div className="partner-carousel__item">
          <PartnerCard
            height="3.5rem"
            altText="Red Cross"
            logo={RedCross}
            link="https://www.redcross.ca/"
          />
        </div>
        <div className="partner-carousel__item">
          <PartnerCard
            height="7rem"
            altText="Lions International"
            logo={Lions}
            link="https://www.lionsclubs.org/en"
          />
        </div>
        <div className="partner-carousel__item">
          <PartnerCard
            height="15rem"
            altText="Al Mawrid Institute"
            logo={Mawrid}
            link="https://almawridinstitute.ca/"
          />
        </div>
        <div className="partner-carousel__item">
          <PartnerCard
            height="7rem"
            altText="UNHCR"
            logo={UNHCR}
            link="https://www.unhcr.org/"
          />
        </div>
        <div className="partner-carousel__item">
          <PartnerCard
            height="7rem"
            altText="International Network for Aid, Relief and Assistance"
            logo={Inara}
            link="https://www.inara.org/"
          />
        </div>
        <div className="partner-carousel__item">
          <PartnerCard
            height="5rem"
            altText="Ontario Nonprofit Network"
            logo={OON}
            link="https://theonn.ca/"
          />
        </div>
        <div className="partner-carousel__item">
          <PartnerCard
            height="3.5rem"
            altText="Ontario Government"
            logo={Ontario}
            link="https://www.ontario.ca/"
          />
        </div>
        <div className="partner-carousel__item">
          <PartnerCard
            height="4rem"
            altText="Employment and Social Development Canada"
            logo={ESDC}
            link="https://www.canada.ca/en/employment-social-development.html"
          />
        </div>
        <div className="partner-carousel__item">
          <PartnerCard
            height="7rem"
            altText="Quick Works"
            logo={QuickWorks}
            link="https://quickworks.pk/"
          />
        </div>
        <div className="partner-carousel__item">
          <PartnerCard
            height="7rem"
            altText="Spark"
            logo={Spark}
            link="https://www.sparkontario.ca/"
          />
        </div>
        <div className="partner-carousel__item">
          <PartnerCard
            height="7rem"
            altText="Malton Women Council"
            logo={MWC}
            link="https://mwc.community/"
          />
        </div>
      </AliceCarousel>
    </div>
  );
}
