import React from 'react';
import { Link } from "react-router-dom";

// Each individual slide of the carousel

export default function HomeCarouselSlide({
  header,
  subheader = null,
  link = null,
  linkHeader = null,
  background,
}) {
  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <div className="home-carousel__body">
        <h1 className="header">{header}</h1>
        {/* Only display subheader/link if there was one passed in as a parameter */}
        {subheader ? <p className="subheader">{subheader}</p> : <></>}
        {link ? (
          <Link className="button-style" to={link}>
            {linkHeader}
          </Link>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
