import React from "react";
import HeroBanner from "../../../components/HeroBanner";
import "./SummerStudentProgram.scss";

export default function SummerStudentProgram() {
  return (
    <div className="our-work">
      <div className="hero-banner-container">
        <HeroBanner title="Initiatives" />
      </div>
      <div className="commit-section__text-container">
        <h1 className="commitment-title">Summer Student Program</h1>
        <p className="text-content">
          Sinai Foundation Canada has proudly collaborated with Employment and
          Social Development Canada (ESDC) in the Canada Summer Job program,
          hiring university students since May 2021. This ongoing program is
          designed to provide continuous coaching and mentoring, enabling
          students to gain valuable work experience, develop new skills, enhance
          existing ones, and network for future career opportunities.
        </p>
        <h2 className="commitment-title">Program Overview</h2>
        <p className="text-content">
          The Canada Summer Job program offers students two distinct
          opportunities:
        </p>
        <ul className="program-list">
          <li>
            <strong>Real-Life Project Execution:</strong>
            <ul>
              <li>
                E-commerce Website Development: Students design and develop
                fully functional e-commerce websites.
              </li>
              <li>
                Business Website Creation: Students work on creating
                professional business websites.
              </li>
              <li>
                Database Application Development: Students are involved in
                developing robust database applications.
              </li>
            </ul>
          </li>
          <li>
            <strong>Teaching Experience:</strong>
            <ul>
              <li>
                JavaScript Classes: Students teach JavaScript programming to
                high school students.
              </li>
              <li>
                Python Classes: Students conduct Python programming classes for
                high school students.
              </li>
            </ul>
          </li>
        </ul>
        <h2 className="commitment-title">Duration and Learning Experience</h2>
        <p className="text-content">
          The program runs for 8-9 weeks each summer, during which students not
          only work on these exciting projects but also gain insights into
          non-profit activities and their impact on the community. By
          participating in this program, students learn how non-profit
          organizations operate and contribute to community welfare.
        </p>
        <h2 className="commitment-title">Program Goals</h2>
        <ul className="program-goals-list">
          <li>
            Skill Development: Students acquire new skills and enhance their
            existing ones through hands-on projects and teaching experiences.
          </li>
          <li>
            Career Networking: The program provides students with networking
            opportunities to help pave the way for future career prospects.
          </li>
          <li>
            Community Impact: Students understand the significance of non-profit
            activities and how they benefit the community.
          </li>
        </ul>
        <p className="text-content">
          Through the Canada Summer Job program, Sinai Foundation Canada is
          committed to empowering the next generation of professionals,
          equipping them with the knowledge, skills, and experience needed for
          their future careers. This collaborative approach not only enhances
          educational outcomes but also empowers students to play a proactive
          role in community development and organizational advancement. These
          partnerships aim to broaden access to quality education, develop
          robust learning resources, and foster digital literacy in the
          communities. Through strategic alliances, we optimize resources and
          expertise to drive sustainable change, ensuring inclusive growth and
          empowering individuals globally.
        </p>
        <h2 className="commitment-title">Additional Initiatives</h2>
        <p className="text-content">
          Sinai Foundation Canada collaborated with a local NGO to implement a
          sustainable energy solution in remote areas of Pakistan by installing
          solar power systems. These systems not only provided reliable
          electricity but also contributed to reducing environmental impact. In
          addition to the installation, Sinai Foundation Canada conducted basic
          technical training for the local community to ensure they could
          effectively maintain and operate the solar systems. This initiative
          not only empowered the community by providing access to essential
          services but also fostered local capacity building, promoting
          long-term sustainability and resilience in the region.
        </p>
        <p className="text-content">
          Sinai Foundation Canada allocated funds to a local NGO, enabling the
          successful completion of a project to pave a brick road connecting two
          villages. This initiative aimed to enhance accessibility and
          transportation between communities, thereby improving connectivity and
          safety for residents. Through careful planning and dedicated efforts,
          the team ensured the project was completed on schedule, delivering a
          robust and sustainable infrastructure solution that promises long-term
          benefits for the local population.
        </p>
      </div>
    </div>
  );
}
