import React from "react";
import HeroBanner from "../../../components/HeroBanner";
import BoardMember from "../../../components/BoardMember";
import President from "../../../components/BoardMember/President";

import { data } from "./info.js";
import "./Leadership.scss";

export default function Leadership() {
  const renderMembers = () => {
    return data.map((member, index) => {
      if (index === 0) {
        return (
          <President
            key={index}
            picture={member.image}
            title={member.title}
            name={member.name}
            info={member.info}
          />
        );
      } else {
        return (
          <BoardMember
            key={index}
            memberImg={member.image}
            memberTitle={member.title}
            memberName={member.name}
            memberInfo={member.info}
          />
        );
      }
    });
  };

  return (
    <div className="leadership">
      <HeroBanner
        title="Leadership"
        body="At Sinai Foundation Canada, each member of our Board of Directors is a driven, like-minded individual from a wide range of backgrounds, all focused on the well-being and livelihood of our community. Details about each member can be found below."
      />
      <div className="main-ld-body">
        <div className="main container-fluid">
          <div className="row">{renderMembers()}</div>
        </div>
      </div>
    </div>
  );
}
