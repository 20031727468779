// image imports
// The images are stored in CanadaDay1 and so on.
import CanadaDay1 from "../../assets/images/Blog/CanadaDay/CanadaDay_1.jpg";
import CanadaDay2 from "../../assets/images/Blog/CanadaDay/CanadaDay_2.jpg";
import CanadaDay3 from "../../assets/images/Blog/CanadaDay/CanadaDay_3.jpg";
import flag from "../../assets/images/Blog/CanadaDay/flag.jpg";
import youngAdults from "../../assets/images/Blog/young_adult.jpg";
import seniors from "../../assets/images/Blog/seniors.jpg";
import cabinFever from "../../assets/images/Blog/cabin_fever.jpg";

// blog data
export const blogPages = [
  // To create a new entry for this page, create a new object and pass the following parameters: id, img, title, description, body (this is written in html/jsx).
  {
    id: "canada-day", // what shows up in the url below
    img: flag, // the images that gets displayed in both /blog and blog/canada-day
    title: "Canada Day", // title that gets displayed in both /blog and blog/canada-day
    description: "The Celebration of Our Nation!", // small description text gets displayed in /blog
    body: (
      <div>
        <h4>
          We would like to begin by acknowledging the Indigenous Peoples of the
          land on which we stand today. We gather on the lands of the
          traditional territory of the Wendat, the Anishnaabeg, Haudenosaunee,
          Métis, and the Mississaugas of the Credit First Nation.
        </h4>

        <article>
          July 1st commemorates the anniversary of the Constitution Act of
          Canada that united the three territories of past Upper and Lower
          Canada, Nova Scotia, and New Brunswick into a self-governing dominion
          under the British Empire on July 1st, 1867.
          <ul className="mt-3">
            <li>
              Since 1867, Canada has expanded to the 13 provinces and
              territories we know today, the most recent to join the union being
              Nunavut, in 1999.
            </li>
            <li>
              Canada Day marks the proclamation of Canada's independence after
              the British parliament put the British North America Act into
              effect, making the nation 155 years old in 2022.
            </li>
          </ul>
          <h5>Historical Facts:</h5>
          <ul>
            <li>
              On July 1, 1867 John A. MacDonald became the first Prime Minister
              of Canada.
            </li>
            <li>
              On July 1, 1967 The 100th anniversary of Confederation was
              celebrated with a high-profile ceremony upon Parliament Hill, it
              included the participation of Her Majesty Queen Elizabeth II.
            </li>
            <li>
              July 1 was previously called "Dominion Day" and was changed to
              "Canada Day" on October 27, 1982. On July 1, 1980 "O Canada"
              became Canada's official national anthem and was originally
              written in French.
            </li>
          </ul>
          <p>
            Since 2013, Canada day has marked the first day of Canada History
            week, a day to encourage citizens to learn more about our nation. A
            week spent visiting museums, memorials, monuments, and important
            sites throughout the first week of July. People celebrate with
            outdoor activities such as, festivals, fireworks, parades, live
            music, sporting events, picnics.
          </p>
          <h5>Some of the biggest Canada Day celebrations:</h5>
          <ul>
            <li>
              Surrey Canada Day, Bill Reid Millennium Amphitheatre, Vancouver
              <ul>
                <li>
                  Carnival, music, entertainment, indignous cultural
                  appreciation
                </li>
              </ul>
            </li>
            <li>
              Canada day fireworks at Canada's wonderland, Vaughn, Ontario
              <ul>
                <li>
                  One of the biggest canada day celebration in the GTA hosted
                  yearly by the popular amusement park
                </li>
              </ul>
            </li>
            <li>
              Ottawa, capital city of Canada
              <ul>
                <li>
                  Historically celebrated on Parliament hill, but relocated to
                  LeBreton Flats Park in front of the Canadian War Museum for
                  2022 renovations
                </li>
              </ul>
            </li>
          </ul>
          <p>
            Our shared Canadian values are to respect, serve, be compassionate,
            and carry out equality and justice. We celebrate a population of
            enormous ethnic variety that represents our tolerant attitudes
            towards multiculturalism and diversity. On this day we recognize and
            give thought to our past and what signifies our union as a single
            independent nation.
          </p>
          <div className="row row-cols-lg-3 row-cols-sm-2 row-cols-1 g-4 mt-4">
            <img src={CanadaDay1} className="col" />
            <img src={CanadaDay2} className="col" />
            <img src={CanadaDay3} className="col" />
          </div>
          <small>Images from: Insauga.ca and Mississauga news</small>
          <br />
          <br />
          <small>
            References:
            <ul>
              <li>
                Canada Day - July 1. National Today. (2022, March 8). Retrieved
                June 30, 2022, from{" "}
                <a href="https://nationaltoday.com/canada-day/">
                  https://nationaltoday.com/canada-day/
                </a>
              </li>
              <li>
                Government of Canada. (2021, May 13). History of Canada Day.
                Canada.ca. Retrieved June 30, 2022, from{" "}
                <a href="https://www.canada.ca/en/canadian-heritage/services/canada-day-history.html">
                  https://www.canada.ca/en/canadian-heritage/services/canada-day-history.html
                </a>
              </li>
            </ul>
          </small>
        </article>
      </div>
    ),
  },
  // Blog entry 2
  {
    id: "covid-19-young-adults",
    img: youngAdults,
    title: "COVID-19 and Mental Health (Young Adults)",
    description: "Tips regarding COVID-19 and Mental Health",
    body: (
      <div>
        <h4>
          COVID-19 pandemic has changed the world in different ways, having
          impacts on our physical and mental health. For this reason, it is
          important to look after our mental well-being. After two years of the
          pandemic, most of us can feel exhausted, anxious, or depressed about
          this situation and all its related effects that disrupted our normal
          life with higher complexity and uncertainty.
        </h4>

        <article>
          In order to better address this topic, we share helpful mental health
          tips and advice that could be beneficial to take into consideration
          for everyone, but especially for youth adults:
          <ul className="mt-3">
            <li>
              Remain aware of our mental health while staying at home;
              identifying our thoughts, emotions and behaviours.
            </li>
            <li>Maintain good social relationships with family and friends.</li>

            <li>
              Create a daily routine that prioritizes looking after yourself.
              You can alternate responsibilities with things that you enjoy
              doing during the day. Try to write them down.
            </li>
            <li>
              Practice mindfulness and breathing techniques that help you relax.
            </li>
            <li>
              Try to learn new things through new activities at home or consider
              studying online.
            </li>
            <li>
              Do physical activity to maintain a healthy body and eat healthy
              food.
            </li>
            <li>
              Spend some time away from your devices, maintain a small presence
              online to avoid watching negative news all the time.
            </li>
            <li>
              Be kind to yourself and be empathic/compassionate towards others
              as well.
            </li>
            <li>
              Look for further support if required, either from family members
              or professionals.
            </li>
            <li>
              Once in a while try to do activities outdoors by yourself or with
              other people.
            </li>
            <li>
              Support the community, either by assisting them or volunteering
              for local community initiatives. This will help you get engaged
              with the community and develop new skills and connections.
            </li>
          </ul>
          <p>
            If you are noticing that your symptoms of anxiety (related to
            COVID-19 or otherwise) are causing you significant distress or are
            interfering with your ability to function normally, consider
            participating in Ontario’s Structured Psychotherapy Program, which
            offers a mix of services, including CMHA’s BounceBack program.
            BounceBack is a free skill-building program for adults and youth 15+
            who want to gain practical life skills to help them better manage
            their symptoms of low mood, mild-to-moderate depression and anxiety,
            stress or worry. For more information or to see if the BounceBack
            program may be right for you, visit{" "}
            <a href="https://bouncebackontario.ca/">bounceback.ca</a>
          </p>
        </article>
      </div>
    ),
  },

  // Blog Entry # 3
  {
    id: "covid-19-seniors",
    img: seniors,
    title: "COVID-19 and Mental Health (Seniors)",
    description: "Tips regarding COVID-19 and Mental Health for Seniors",
    body: (
      <div>
        <h4>
          It is normal to be feeling increased levels of stress and anxiety due
          to the ongoing COVID-19 pandemic. As social gathering restrictions and
          economic shutdowns have remained in effect for far longer than
          governments first anticipated, we understand that the activities and
          coping strategies you relied on to get through the past year may not
          be working as well as they used to.
        </h4>

        <article>
          If you have been getting by but do not feel like things are getting
          better, try to consider some helpful mental health tips and advice
          that could be beneficial for everyone, but especially for seniors:
          <ul className="mt-3">
            <li>
              Identify a circle of support by building and maintaining
              connections. Remember to take into account long-term family
              members and friends.
            </li>
            <li>
              Try to do something nice for yourself every day and celebrate
              small accomplishments.
            </li>

            <li>
              Try to express yourself assertively (be open about your thoughts
              and feelings).
            </li>
            <li>
              Keep a note of things that you are grateful for, at least identify
              one positive thing during each day.
            </li>
            <li>
              Practice mindfulness and breathing techniques that will help you
              relax.
            </li>
            <li>
              Do any physical activity you feel comfortable with (at least 3
              times a week) to maintain a healthy body, as well as eat healthy
              foods of your preference.
            </li>
            <li>
              Make an effort to go for walks with social distancing in mind, in
              backyards, parks or on empty streets; anywhere to gain exposure to
              sunlight and fresh air.
            </li>
            <li>
              Do not stay on your phone or tv for long periods of time and avoid
              watching negative news all the time.
            </li>
            <li>
              Focus on the present, instead of focusing too much on the past, be
              open to the new opportunities of each day.
            </li>
            <li>
              Be compassionate and accepting of yourself (forgiving any prior
              mistakes), recognizing that we all are human and imperfect, but
              the present always allows room for improvement every day.
            </li>
            <li>
              Look out for different local organizations and social groups that
              may be providing support and recreational activities for seniors.
            </li>
            <li>
              Consider supporting others according to your best capacity, for
              example volunteering for local community initiatives. This will be
              a great way to give back that can be beneficial for everyone
              (including yourself), keeping engaged and active within the
              community.
            </li>
            <li>
              Avoid unhealthy coping strategies such as alcohol, tobacco, online
              gambling and recreational drugs.
            </li>
          </ul>
          <p>
            As this can be a stressful time for many, there are numerous
            resources available to help relieve some of this stress. If you are
            worried about getting sick, have vaccine-related questions, are
            wondering what you should do if you develop symptoms of COVID-19,
            would like to take care of your mental and physical health, would
            like to gain financial support, would like to protect yourself from
            fraud/elder abuse and much more, please check out{" "}
            <a href="https://www.canada.ca/en/public-health/services/diseases/coronavirus-disease-covid-19/resources-older-adults-caregivers.html">
              COVID-19: Resources for seniors and their caregivers
            </a>
            . These resources have been compiled by the Government of Canada and
            are made available in order to better help support not only seniors
            but also their caregivers.
          </p>
          <small>
            Additional Readings:
            <ul>
              <li>
                <a href="https://ontario.cmha.ca/news/try-simple-but-helpful-methods-to-manage-mental-health-during-this-stay-at-home-period/">
                  https://ontario.cmha.ca/news/try-simple-but-helpful-methods-to-manage-mental-health-during-this-stay-at-home-period/
                </a>
              </li>
              <li>
                <a href="https://nygh.on.ca/newsroom/looking-after-your-mental-health-during-pandemic">
                  https://nygh.on.ca/newsroom/looking-after-your-mental-health-during-pandemic
                </a>
              </li>
              <li>
                <a href=" https://www.camh.ca/en/health-info/mental-health-and-covid-19">
                  https://www.camh.ca/en/health-info/mental-health-and-covid-19
                </a>
              </li>
            </ul>
          </small>
        </article>
      </div>
    ),
  },

  // Blog entry 4

  {
    id: "cabin-fever",
    img: cabinFever,
    title: "Cabin Fever",
    description: "Tips to overcome Cabin Fever",
    body: (
      <div>
        <h4>
          Cabin Fever is a psychological condition that many people can
          experience as a consequence of the COVID-19 pandemic. It is mainly
          associated with a great fear of going outside. This is a problem of
          adapting to a new perception of the world. Its side effects can
          increase levels of stress, anxiety and physical related symptoms such
          as insomnia, tiredness, irritability and difficulty concentrating.
          This can happen because the brain does not handle a sudden change of
          routine very well, many found it very difficult to accept the initial
          confinement and have been in isolation for several months (or even
          years), so many will find it difficult to make a new change to return
          to normal and readjust to the outside world after two years of the
          COVID-19 pandemic.
        </h4>

        <article>
          This is because many people have already internalized the house as the
          only place where they can be safe. This was correct some time ago due
          to the increase in COVID-19 cases, but as it gradually decreases, we
          should try our best to adapt better and be active members of society.
          So in order to improve against Cabin Fever, we share the following six
          tips.
          <ol className="mt-3">
            <li>
              Recognize that it is normal to be afraid or anxious after
              confinement. Start by identifying and acknowledging your fears and
              concerns, as you make them aware you can make the effort to
              improve on them.
            </li>
            <li>
              Take all preventive measures when you go out, respecting social
              distance and using a mask that you have available in closed
              places.
            </li>

            <li>
              Go out gradually, little by little until you get used to the
              outside, do it at your own pace, without pressuring yourself, but
              be aware that every time you go out, nothing bad is actually
              happening.
            </li>
            <li>
              Visualize going out successfully. If you have a hard time going
              out, try to imagine it or think about it and your brain will begin
              little by little to accept the idea of ​​being able to do it, in
              reality, sooner or later.
            </li>
            <li>
              Avoid thinking that the worst is a fact; stop magnifying the
              negative and focus on improving your responsiveness, you have the
              capacity to overcome your fears.
            </li>
            <li>
              Finally, remember that every crisis is temporary, although
              COVID-19 has been something extraordinary, this is not the first
              time you have faced a problem or crisis in life. Remember how you
              have overcome past challenges and also reflect on your past
              achievements, there is always room for improvement. Try your best
              and trust in yourself!
            </li>
          </ol>
        </article>
      </div>
    ),
  },
];
