import React from "react";
import HeroBanner from "../../../components/HeroBanner";
import img from "../../../assets/Projects/food5.jpg";
import foodImg from "../../../assets/Projects/food2.jpg";
export default function Index() {
  // using majority inline styles since page is so small
  const styles = {
    "image-section": {
      justifyContent: "center",
      alignItems: "center",
    },
    img: {
      width: "clamp(150px, 25vw, 25%)",
      height: "initial",
    },
  };
  return (
    <div>
      <HeroBanner
        title="Emergency Food Services"
        body="Sinai Foundation Canada provides emergency food supply for those who are in need, prioritizing seniors and the homeless."
      />
      <div className="main">
        <p>
          Sinai Foundation Canada is committed to supporting those who are the
          most in need, especially by addressing poverty and hunger. For this
          reason, we currently run different food programs that benefit the
          community by providing healthy and accessible food in partnership with
          other local organizations.
        </p>
        <div className="row" style={styles["image-section"]}>
          <img className="col-auto" src={img} style={styles["img"]} />
          <img className="col-auto" src={foodImg} style={styles["img"]} />
        </div>
      </div>
    </div>
  );
}
