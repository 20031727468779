// card similar to standard board member card but is adjusted to be larger and more emphasized

import React from 'react';
import './President.scss';

export default function President({ picture, name, title, info }) {
  return (
    <div className="president row">
      <img
        src={picture}
        className="president__img col-12 col-lg-6"
        alt={name}
      />
      <div className="president__content col-12 col-lg-6">
        <h5 className="president__name">{name}</h5>
        <h5 className="president__title">{title}</h5>
        <p className="president__info">{info}</p>
      </div>
    </div>
  );
}