import React from "react";
import ResourceCard from "../ResourceCard";

// Resources section on the mental health project page

export default function OtherFoodbankResources() {
  return (
    // Resource cards are from the ResourceCard folder
    <section className="row row-cols-xxl-3 row-cols-xl-2 row-cols-md-1 row-cols-sm-2 row-cols-1 g-4">
      <div className="col">
        <ResourceCard
          title="211 Ontario"
          titleLink="https://211ontario.ca/211-topics/food/food-banks-and-referrals/"
          content={[
            <>
              Committed to feeding the hungry in the community through food
              distribution.
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Central Health Line CA"
          titleLink="https://www.centralhealthline.ca/listservices.aspx?id=10572"
          content={[
            <>
              Distributes fresh and shelf-stable food every day to their member
              agency networks.
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Feed Ontario"
          titleLink="https://feedontario.ca/about-us/find-a-food-bank/"
          content={[
            <>
              Provides food-oriented services to individuals primarily residing
              in Georgetown
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Food Banks Canada"
          titleLink="https://www.foodbankscanada.ca/"
          content={[
            <>
              Serves individuals, families and communities who are vulnerable,
              in distress and lack access to other services because of obstacles
              of culture, language, race, poverty, gender, or other barriers.
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Muslim Foodbank Community Services"
          titleLink="https://muslimfoodbank.com/aspire/"
          content={[
            <>
              Fight against poverty and homelessness and are focused on
              providing food, health care services, culturally-sensitive shelter
              for women, their children and families in distress, and healthy
              nutrition to our school children and seniors.
            </>,
          ]}
        />
      </div>
    </section>
  );
}
