import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import SideMedia from "./components/SideMedia/SideMedia";
import BackToTop from "./components/BackToTop";
import ScrollToTop from "./Config/ScrollToTop";
import Home from "./pages/Home/index";
import About from "./pages/About";
import OurWork from "./pages/OurWork";
import OurServices from "./pages/OurServices";
import OurProjects from "./pages/OurProjects";
import JoinUs from "./pages/JoinUs";
import Contact from "./pages/Contact";
import Donation from "./pages/Donation";
import Blog from "./pages/Blog";
import BlogPage from "./pages/Blog/BlogPage";

import "./App.scss";

export default function App() {
  return (
    <div className="App">
      <Router>
        {/* ScrollToTop is a config that autoscrolls to the top of the page when ever the route is changed. Should not be confused with the scrolltotop button on most pages */}
        <ScrollToTop />
        <Navbar />{" "}
        {/* This is where the navbar is being rendered, make changes in Navbar component if you want to add more navigation options in it*/}
        <SideMedia />
        {/* React switch which handles redirects to each page */}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
                  <Route path="/our-work" component={OurWork} />
                  <Route path="/our-projects" component={OurProjects} />
          <Route path="/our-services" component={OurServices} />
          <Route path="/join-us" component={JoinUs} />
          <Route path="/contact" component={Contact} />
          <Route path="/donate" component={Donation} />
          <Route path="/blog/:id" component={BlogPage} />
          <Route path="/communication" component={Blog} />
        </Switch>
        <BackToTop />
        <Footer />
      </Router>
    </div>
  );
}
