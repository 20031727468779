import React from "react";
import HeroBanner from "../../../components/HeroBanner";
import image from "../../../assets/images/OurServices/image.png";
import walker from "../../../assets/images/OurServices/walker.png";
import walker2 from "../../../assets/images/OurServices/walker2.png";
import walking from "../../../assets/images/OurServices/walking.png";
import wheelchair from "../../../assets/images/OurServices/wheelchair.png";

export default function Index() {
  // using majority inline styles since page is so small
  const styles = {
    "image-section": {
      justifyContent: "center",
      alignItems: "center",
    },
    img: {
      width: "clamp(150px, 25vw, 10%)",
      height: "initial",
    },
  };

  const images = [image, walker, walker2, walking, wheelchair];

  return (
    <div>
      <HeroBanner
        title="Distributing Health and Safety Supplies"
        body="Sinai Foundation Canada supplies health care products and equipment for physically challenged persons, prioritizing seniors."
      />
      <div className="main">
        <p>
          In 2022, Sinai Foundation Canada, in partnership with other local
          organizations, obtained different healthcare related equipment to
          distribute to those in need; especially to the seniors living within
          Mississauga.
        </p>
        <p>
          We donated good quality wheelchairs, safety rails and walkers with
          seats so people are able to better mobilize themselves and get the
          opportunity to do their day-to-day activities in a more safe and
          convenient manner.
        </p>
        <div className="row" style={styles["image-section"]}>
          {images.map((image, key) => (
            <img
              className="col-auto"
              style={styles["img"]}
              src={image}
              key={key}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
