// This flipbook is currently ONLY designed for displaying PDFs

import React, { useEffect, useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import { Document, Page, pdfjs } from "react-pdf";
import { useLocation } from "react-router-dom";
import "./FlipBook.scss";

// value required to allow pages to load; do not delete
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default function FlipBook({ pdf, length }) {
  // state variable that stores the calculated width and height of the flipbook
  const [pageSize, setPageSize] = useState([]);
  const pathname = useLocation().pathname;

  // useEffect will only run when the page is loaded; similar to componenetDidMount
  useEffect(() => {
    // do not run check if the flipbook is not on the screen
    if (pathname === "/projects") {
      return;
    }

    const handleResize = () => {
      const textElements =
        document.getElementsByClassName("our-projects__text");
      if (textElements.length === 0) {
        // Element not found, return early
        return;
      }

      // take width of the text container as reference
      let width = textElements[0].offsetWidth,
        pageWidth = window.innerWidth;

      // only allow landscape mode if the screen width >= 1700px
      if (pageWidth >= 1700) {
        setPageSize([width / 2, width / 1.5]);
      } else if (width <= 350) {
        setPageSize([width * 1.15, width * 1.5]);
      } else {
        setPageSize([width, width * 1.3]);
      }
    };

    // Initial call to set sizes
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [pathname]);

  // functions that will be used to control the booklet via external buttons
  const pageRef = useRef();
  const nextButtonClick = () => {
    pageRef.current.pageFlip().flipNext();
  };

  const prevButtonClick = () => {
    pageRef.current.pageFlip().flipPrev();
  };

  // generate pages and set sizes depending on current screen size
  const pages = [];
  // push first page, which runs a function to turn to the first page of the flipbook once it is loaded
  pages.push(
    <div key={0}>
      <Page
        renderTextLayer={false}
        renderAnnotationLayer={false}
        pageIndex={0}
        width={pageSize[0]}
        className="page-flip"
        onLoadSuccess={() => {
          pageRef.current.pageFlip().turnToPage(0);
        }}
      />
    </div>
  );
  for (let i = 1; i < length; i++) {
    pages.push(
      <div key={i}>
        <Page
          renderTextLayer={false}
          renderAnnotationLayer={false}
          pageIndex={i}
          width={pageSize[0]}
          className="page-flip"
        />
      </div>
    );
  }

  return (
    <div className="booklet mt-4">
      <Document file={pdf}>
        <HTMLFlipBook
          className="flipbook"
          width={pageSize[0]}
          height={pageSize[1]}
          flippingTime={500}
          useMouseEvents={window.innerWidth >= 576 ? true : false}
          ref={pageRef}
        >
          {pages}
        </HTMLFlipBook>
      </Document>

      <div className="btn-block" style={{ margin: "0rem 0rem 3rem 0rem" }}>
        <button onClick={prevButtonClick} className="page-flip-btn">
          Previous page
        </button>
        <button onClick={nextButtonClick} className="page-flip-btn">
          Next page
        </button>
        <form action={pdf} target="blank" className="pdf-block">
          <button type="submit" className="page-open-btn">
            <i className="fas fa-file-alt" /> {"  "}
            Open Booklet In New Tab
            <br />{" "}
          </button>
        </form>
      </div>
    </div>
  );
}
