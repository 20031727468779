import React, { useRef, useState } from 'react';
import HeroBanner from '../../../components/HeroBanner';
import { Form, Input, CheckBox, Submit } from "../../../components/Form/index";
import FormModal from '../../../components/FormModal';
import axios from 'axios';
import { API_URL } from '../../../Config/config';

// Volunteer page

export default function Index() {
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const fileInput = useRef();

    const onSubmit = ({ name, email, phone, program, seasonal, weekly, daily, refname1, refname2, refphone1, refphone2 }) => {

        setLoading(true);
        setError('');

        const fileData = new FormData()
        fileData.append('resume', fileInput.current.files[0]);
        fileData.append('subject', 'Involvment Registration');
        fileData.append('text', `CONTACT INFO\nName: ${name}\nEmail: ${email}\nPhone Number: ${phone}
        \nINTERESTED PROGRAM(S): ${program ? program : 'N/A'}
        \nAVAILABILTY\nSeasonal: ${seasonal ? seasonal : 'N/A'}\nWeekly: ${weekly ? weekly : 'N/A'}\nDaily: ${daily ? daily : 'N/A'}
        \nREFERENCE\nRef 1\nName:${refname1 ? refname1 : 'N/A'} Phone: ${refphone1 ? refphone1 : 'N/A'}\nRef 2\nName:${refname2 ? refname2 : 'N/A'} Phone: ${refphone2 ? refphone2 : 'N/A'}`);

        axios.post(`${API_URL}/email/attachment`, fileData)
            .then(({ data }) => {
            }).catch(({ response }) => {
                if (response === undefined) setError(['503', 'Service Unavailable']);
                else setError([response.status, response.data]);
            }).finally(() => {
                setLoading(false);
            });

        setShowModal(true);
    }

    return (
        <div>
            <FormModal
                showModal={showModal}
                setShowModal={setShowModal}
                text='Your involvment registration has been submited.'
                error={error}
                setError={setError}
                loading={loading}
                setLoading={setLoading}
            />
            <HeroBanner title='Be Involved' />
            <div className="main container-fluid mt-5">
                <div className="shadow card-style p-4 mx-auto mt-5 mb-5" style={{ maxWidth: '50rem' }}>
                    <Form className='mb-3' onSubmit={onSubmit}>
                        <h1 className='header mt-3 mb-5 mx-auto short-underline'>Involvement Application Form</h1>
                        <h2 className='subheader mt-3 mb-5 short-underline-left'>Your Information</h2>
                        <Input
                            className="col-12"
                            name="name"
                            placeholder="Name *"
                            required={{
                                required: "Please Enter Your First Name",
                                maxLength: 50,
                            }}
                        />
                        <Input
                            className="col-12"
                            name="email"
                            placeholder="Email *"
                            required={{
                                required: "Please Enter A Valid Email Address",
                                pattern: {
                                    value:
                                        // eslint-disable-next-line no-useless-escape
                                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                                    message: "Please Enter A Valid Email Address",
                                },
                            }}
                        />
                        <Input
                            className="col-12"
                            type='tel'
                            name="phone"
                            placeholder="Phone Number *"
                            required={{
                                required: "Please Enter A Valid Phone Number",
                                pattern: {
                                    value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                                    message: "Please Enter A Valid Phone Number",
                                },
                            }}
                        />
                        <h2 className='subheader mt-5 mb-5 short-underline-left'>Type of Volunteer Work That You Would Be Interested In</h2>
                        <div className='row'>
                            <CheckBox className='col-12 col-md-6' name='program' placeholder='Grocery Shopping' />
                            <CheckBox className='col-12 col-md-6' name='program' placeholder='Reporting/Bookkeeping' />
                            <CheckBox className='col-12 col-md-6' name='program' placeholder='Food Preparation/Distribution' />
                            <CheckBox className='col-12 col-md-6' name='program' placeholder='Program Coordination' />
                        </div>
                        <h2 className='subheader mt-5 mb-5 short-underline-left'>Availability</h2>
                        <h6 className='lead mb-1 mt-4'>Please select seasonal availability</h6>
                        <div className='row divider'>
                            <CheckBox className='col-6 col-sm-4 col-lg-3' name='seasonal' placeholder='Summer' />
                            <CheckBox className='col-6 col-sm-4 col-lg-3' name='seasonal' placeholder='Fall' />
                            <CheckBox className='col-6 col-sm-4 col-lg-3' name='seasonal' placeholder='Winter' />
                            <CheckBox className='col-6 col-sm-4 col-lg-3' name='seasonal' placeholder='Spring' />
                        </div>
                        <h6 className='lead mb-1 mt-4'>Please select your weekly availability</h6>
                        <div className='row divider'>
                            <CheckBox className='col-6 col-sm-4 col-lg-3' name='weekly' placeholder='Monday' />
                            <CheckBox className='col-6 col-sm-4 col-lg-3' name='weekly' placeholder='Tuesday' />
                            <CheckBox className='col-6 col-sm-4 col-lg-3' name='weekly' placeholder='Wednesday' />
                            <CheckBox className='col-6 col-sm-4 col-lg-3' name='weekly' placeholder='Thursday' />
                            <CheckBox className='col-6 col-sm-4 col-lg-3' name='weekly' placeholder='Friday' />
                            <CheckBox className='col-6 col-sm-4 col-lg-3' name='weekly' placeholder='Saturday' />
                            <CheckBox className='col-6 col-sm-4 col-lg-3' name='weekly' placeholder='Sunday' />
                        </div>
                        <h6 className='lead mb-1 mt-4'>Please select your daily availability</h6>
                        <div className='row'>
                            <CheckBox className='col-6 col-sm-4 col-lg-3' name='daily' placeholder='Morning' />
                            <CheckBox className='col-6 col-sm-4 col-lg-3' name='daily' placeholder='Afternoon' />
                            <CheckBox className='col-6 col-sm-4 col-lg-3' name='daily' placeholder='Evening' />
                        </div>
                        <h2 className='subheader mt-5 mb-5 short-underline-left'>References</h2>
                        <div className='row'>
                            <Input
                                className="col-12 col-md-6"
                                name="refname1"
                                placeholder="Reference 1 Name"
                            />
                            <Input
                                className="col-12 col-md-6"
                                type='tel'
                                name="refphone1"
                                placeholder="Reference 1 Phone"
                            />
                            <Input
                                className="col-12 col-md-6"
                                name="refname2"
                                placeholder="Reference 2 Name"
                            />
                            <Input
                                className="col-12 col-md-6"
                                type='tel'
                                name="refphone2"
                                placeholder="Reference 2 Phone"
                            />
                        </div>
                        <div className='row mb-4'>
                            <label className='lead mb-2 mt-4' htmlFor="resume">Upload Resume</label>
                            <input
                                type="file"
                                className=''
                                id="resume"
                                name="resume"
                                ref={fileInput}
                            />
                        </div>
                        <Submit position='middle' />
                    </Form>
                </div>
            </div>
        </div>
    );
}
