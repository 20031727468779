import React from "react";
import ResourceCard from "../ResourceCard/";

// Resources section on the mental health project page

export default function FoodbankResources() {
  return (
    // Resource cards are from the ResourceCard folder
    <section className="row row-cols-xxl-3 row-cols-xl-2 row-cols-md-1 row-cols-sm-2 row-cols-1 g-4">
      <div className="col">
        <ResourceCard
          title="Eden Food For Change"
          titleLink="https://edenffc.org/"
          content={[
            <>
              Provides food-related services to individuals primarily residing
              in western Mississauga.
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Good Measure Food Bank"
          titleLink="http://goodmeasurefoodbank.com/contact/"
          content={[
            <>Provides food-related and spiritual services to individuals.</>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="ICNA Relief Canada"
          titleLink="https://icnareliefcanada.ca/"
          content={[
            <>
              Is a global humanitarian relief and development organization
              responding to human suffering in emergency and disaster situations
              around the world as well as providing food-specific related
              services.
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Sai Dham Food Bank"
          titleLink="http://www.saidhamfoodbank.com/"
          content={[
            <>
              Aims to feed low-income seniors and the physically disabled who
              could not get to food banks.
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Salvation Army Erin Mills"
          titleLink="https://www.salvationarmyerinmills.com/"
          content={[
            <>Provides services regarding food, clothing and spirituality.</>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Seva Food Bank"
          titleLink="https://www.sevafoodbank.com/"
          content={[
            <>
              It is a Sikh Serving food bank that addresses the challenges of
              food security and underlying poverty to spur economic renewal in
              Mississauga, at its two locations: Malton and Wolfedale.
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="St Mary's Food Bank"
          titleLink="https://stmarysfoodbank.ca/"
          content={[
            <>
              Involves a food distribution system for low-income clients who
              require a helping hand at three locations: Coopers, Dundas and
              Streetsville.
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="The Compass Food Bank"
          titleLink="https://thecompass.ca/"
          content={[
            <>
              Offers immediate assistance with food as well as practical and
              spiritual support for the challenges that often come along with
              low income conducted online using Zoom.
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="The Open Door"
          titleLink="https://opendoorsquareone.org/"
          content={[
            <>
              Is a front-line outreach ministry of the Churches to the community
              of Mississauga that provides food and spiritual related services.
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="The Mississauga Food Bank"
          titleLink="http://www.themississaugafoodbank.org/"
          content={[
            <>
              Is the central food bank in Mississauga that distributes food
              through a network of over 50 agencies including local food banks,
              pantries, meal and snack programs, and other emergency food
              programs.
            </>,
          ]}
        />
      </div>
    </section>
  );
}
