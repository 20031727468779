
import React from 'react';
export default function PreviewCard({ title, onClick, cover, active }) {
  return (
    <div
      className={`preview-card${active ? "__active" : ""}`}
      onClick={onClick}
    >
      <img src={cover} alt={title} />
      <p>{title}</p>
    </div>
  );
}