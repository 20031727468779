import React from "react";
import HeroBanner from "../../../components/HeroBanner";
import InfoComp from "../../../components/InfoComp/";
import SlideCardSection from "../../../components/SlideCardSection/";
import "./MissionAndPhilosophy.scss";

export default function index() {
  return (
      <div className="mission-and-philosophy">

          <HeroBanner
              title="Mission"
          />

          
      <div className="main-body">
        <div className="mt-4 main container-fluid">
                  <div className="mission-and-philosophy-col">
                      <InfoComp
                          title="Our Mission"
                          body="Empowering communities through education, fostering better earning opportunities, promoting health and well-being, raising awareness, and driving sustainability.
                          At Sinai Foundation Canada, our mission is to facilitate lifelong learning, enhance career prospects, support holistic health, and advance sustainable development to create a brighter future for all."
                      />
                      <InfoComp
                          title="Our Vision"
                          body="To create a world where every individual has the opportunity to learn, thrive, and live sustainably. 
                            We envision communities that are educated, healthy, aware, and well, with equitable access to earning opportunities and the resources needed for sustainable development. 
                            Through our dedication to empowerment, we strive to build a future where awareness, well-being, and prosperity are attainable for all."
                      />
                      <InfoComp
                          title="Our Philosophy"
                          body="At Sinai Foundation Canada, our philosophy centers on empowerment through education, health, awareness, and sustainable development. 
                            We believe in fostering lifelong learning, promoting holistic well-being, raising awareness about critical issues, and advancing initiatives that create lasting positive impact in communities. 
                            By collaborating with global partners and leveraging innovative solutions, we aim to inspire change, cultivate resilience, and build a better future for generations to come."
                      />
                      <InfoComp
                          title="Our Reach"
                          body="Sinai Foundation Canada extends its reach across local and global landscapes, committed to empowering communities through diverse initiatives. 
                            From educational programs enhancing digital literacy to healthcare initiatives improving well-being, we collaborate with international partners to foster sustainable development. 
                            Our efforts resonate in projects that promote environmental stewardship, social equity, and economic empowerment, aiming to create a profound and lasting impact in communities worldwide."
                      />
                     
          </div>
                  <hr />
                  <h3>Join Hands With Sinai Foundation Canada</h3>
          <p>
                      Sinai Foundation urges action to prevent daily hardships like job loss, hunger, poor decisions, and loss of life.
                      Positioned as a proactive force for change, we invite you to join us in transforming communities globally.
                      Together, let's create a future where everyone receives the care and support needed for a thriving life.
          </p>
          <div className="mission-and-philosophy__btm-rec-section pb-3">
            <SlideCardSection />
          </div>
        </div>
      </div>
    </div>
  );
}
