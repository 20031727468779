import React from 'react';
import './HeroBanner.scss';

// Large banner on all pages except home page

export default function HeroBanner({ title = '', body = ''}) {
    return (
      <div className="hero-banner">
        <div className="container-fluid">
          <h1 className="hero-banner__title">{title}</h1>
          {body.split("<br>").map((item, i) => (
            <p key={i} className="hero-banner__body">
              {item}
            </p>
          ))}
        </div>
        {/* Red line that runs under the banner */}
        <span className="hero-banner__eyebrow breadcrumb" />
      </div>
    );
}