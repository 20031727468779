import React from "react";
import { Helmet } from "react-helmet";
import { Route, Switch, useRouteMatch } from "react-router-dom";

// component/image imports
import MetaImage from "../../../assets/images/ipicture-orig.jpg";
import Root from "./Pages/root";
import SubPage from "./Pages/subpage";

import "./OurProjects.scss";

export default function Index() {
  const match = useRouteMatch().url;

  return (
    <>
    {/* Metadata */}
      <Helmet>
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Projects by Sinai Foundation" />
        <meta
          property="og:description"
          content="How we've worked with and given back to the community"
        />
        <meta property="og:url" content="https://sinaifoundation.ca/projects" />
        <meta property="og:image" content={MetaImage} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sinai Foundation Canada Projects" />
        <meta
          name="twitter:description"
          content="How we've worked with and given back to the community"
        />
        <meta name="twitter:image" content={MetaImage} />
      </Helmet>
      {/* Routes for the projects */}
      <Switch>
        <Route path={`${match}/:path`} component={SubPage} />
        <Route exact path={`${match}`} component={Root} />
      </Switch>
    </>
  );
}
