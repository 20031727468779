import React from "react";
import HeroBanner from "../HeroBanner";
import "./TechnicalServices.scss";

export default function TechnicalServices() {
  return (
    <main className="technical-services">
      <HeroBanner title="Our Technical Services" />
      <div className="main-content fade-in">
        <h1 className="header">
          Introducing Sinai Foundation Canada: Empowering Communities through
          Technology
        </h1>
        <div className="content-section">
          <p>
            Sinai Foundation Canada is a pioneering non-profit organization
            dedicated to empowering communities through innovative solutions.
            Beyond our core mission of alleviating poverty and addressing
            immediate needs, we offer specialized web design and development
            services. Our tailored services cater specifically to non-profit
            organizations, providing them with high-quality web solutions at
            discounted rates.
          </p>
          <p>
            Additionally, Sinai Foundation Canada is committed to nurturing the
            next generation of talent. We partner with universities across
            Canada to empower students with essential skills for tomorrow's
            workforce. Through mentorship programs and hands-on training in web
            design and development, we equip university students with the tools
            they need to thrive in a digital economy.
          </p>
          <p>
            With a commitment to fostering digital empowerment, Sinai Foundation
            Canada leverages its expertise in web design to help non-profits
            establish and enhance their online presence. By integrating
            user-friendly interfaces, responsive designs, and robust
            functionalities, we ensure that our clients can effectively
            communicate their mission, engage their audience, and expand their
            impact.
          </p>
          <p>
            At Sinai Foundation Canada, we believe in the transformative power
            of technology to drive social change. Through our web design and
            development services, we support non-profits in reaching their
            goals, amplifying their voices, and making a meaningful difference
            in communities locally and beyond. Join us in building a brighter
            future through technology and innovation.
          </p>
          <h2>Our Process</h2>
          <table>
            <thead>
              <tr>
                <th>Phase</th>
                <th>Activities</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Discovery Phase</td>
                <td>
                  Research and Analysis: Understand client's business, audience,
                  competitors, and goals.
                </td>
              </tr>
              <tr>
                <td>Planning Phase</td>
                <td>
                  Define Goals and Objectives: Clarify project scope,
                  deliverables, timelines, and budget.
                </td>
              </tr>
              <tr>
                <td>Design Phase</td>
                <td>
                  Wireframing: Create basic layout and structure of web pages
                  (UI/UX design).
                </td>
              </tr>
              <tr>
                <td>Development Phase</td>
                <td>
                  Front-End Development: Convert designs into functional web
                  pages using HTML, CSS, JavaScript.
                </td>
              </tr>
              <tr>
                <td>Testing Phase</td>
                <td>
                  Quality Assurance (QA): Test for bugs, errors, and usability
                  across devices and browsers.
                </td>
              </tr>
              <tr>
                <td>Launch Phase</td>
                <td>
                  Deployment: Prepare and launch the website on client's hosting
                  server.
                </td>
              </tr>
              <tr>
                <td>Maintenance and Optimization</td>
                <td>
                  Monitoring and Updates: Regularly monitor performance,
                  security, and traffic.
                </td>
              </tr>
            </tbody>
          </table>
          <p className="final-note">
            Thank you for providing us with the opportunity to serve you. We
            look forward to collaborating with you and helping you achieve your
            digital goals while making a positive impact on society.
          </p>
        </div>
      </div>
    </main>
  );
}
