import React from "react";
import "./BackToTop.scss";

export default function BackToTop() {
  return (
    <div className="backtotop">
      <button
        type="button"
        className="btn btn-floating shadow-none btn-lg backtotop__button"
        id="back-to-top"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <i className="fas fa-chevron-up" />
      </button>
      <span className="backtotop__text">Back to Top</span>
    </div>
  );
}
