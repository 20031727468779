import React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import Projects from "./Projects";
import Initiatives from "./Initiatives";
import SummerStudentProgram from "./SummerStudentProgram";
// Route handler for all 3 about pages

export default function Index(props) {
  const match = useRouteMatch().url;

  return (
    <Switch>
      <Route path={`${match}/projects`} component={Projects} />
      <Route path={`${match}/initiatives`} component={Initiatives} />
      <Route
        path={`${match}/summer-student-program`}
        component={SummerStudentProgram}
      />

      <Redirect to={`${match}/`} />
    </Switch>
  );
}
