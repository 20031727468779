import { useState, useEffect } from 'react'

// used for facts cards, which starts counting up once the cards come into view

export default function useOnScreen(ref) {
    const [isIntersecting, setIntersecting] = useState(false)
    const [flag, setFlag] = useState(false);


    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (isIntersecting) setFlag(true);
                (flag) ? setIntersecting(true) : setIntersecting(entry.isIntersecting);
            });
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, [flag, isIntersecting, ref])

    return isIntersecting
}