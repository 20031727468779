import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import Member from './Member';
import Involved from './Involved';

// Route handler for join us pages

function Index(props) {
    const match = useRouteMatch().url;

    return (
        <Switch>
            <Route path={`${match}/member`} component={Member} />
            <Route path={`${match}/involved`} component={Involved} />
            <Redirect to={`${match}/member`} />
        </Switch>
    );
}

export default Index;