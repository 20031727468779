import React from "react";
import Helmet from "react-helmet";
import SinaiLogo from "../../assets/SinaiLogo/sinai-logo.svg";
import Facts from "../../components/Facts/Facts";
import HomeCarousel from "../../components/HomeCarousel/HomeCarousel";
import OurCommitment from "../../components/OurCommitment/";
import OurMission from "../../components/OurMission/";
import BlogCarousel from "../../components/BlogCarousel";
import PartnerCarousel from "../../components/PartnerCarousel/";
import PostSection from "../../components/PostSection/";
import Subscribe from "../../components/Subscribe/Subscribe";

import "./home.scss";

// This is the Home page of the website. Components are being used here for different sections on the home page.

export default function Index() {
  return (
    <div className="container-fluid px-0">
      <Helmet>
        <meta property="og:url" content="http://sinaifoundation.ca" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Sinai Foundation Canada" />
        <meta
          property="og:description"
          content="Non-Profit Organization Based in Mississauga, Ontario"
        />
        <meta property="og:image" content={SinaiLogo} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sinai Foundation Canada" />
        <meta
          name="twitter:description"
          content="Non-Profit Organization Based in Mississauga, Ontario"
        />
        <meta name="twitter:image" content={SinaiLogo} />
      </Helmet>
      {/* Sliding banner background and Goals cards */}
      <HomeCarousel />
      <div className="our-goal"></div>
      <div className="home_field">
        {/* Section containing the two expanding cards */}
        <div id="left_filler" />

        <div className="home_left ">
          <OurMission />
        </div>
        <div className="home_right">
          <OurCommitment />
        </div>
        <div id="mid_filler" />
        <div className="home-blog">
          <BlogCarousel />
        </div>
        <div className="right_filler" />
      </div>
      <div className="main">
        <h2 className="header fw-light d-flex justify-content-center mt-5">
          Foundation Facts
        </h2>
        <Facts />
        <PostSection />
        <h2 className="header fw-light d-flex justify-content-center mt-5">
          Partners &amp; Networks
        </h2>
        <PartnerCarousel />
      </div>
      <Subscribe />
    </div>
  );
}
