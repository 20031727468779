import React, { useRef } from "react";
import AliceCarousel from "react-alice-carousel";
import "./GalleryCarousel.scss";

// Carousel used in project pages that display images from projects

export default function GalleryCarousel({ children }) {
  const ref = useRef();
  return (
    <div className="gallery-carousel">
      {/* Buttons that control the carousel */}
      <button
        className="gallery-carousel__btn gallery-carousel__btn--left"
        onClick={() => ref.current.slidePrev()}
      >
        <i className="gallery-carousel__icon fas fas fa-chevron-circle-left" />
      </button>
      <button
        className="gallery-carousel__btn gallery-carousel__btn--right"
        onClick={() => ref.current.slideNext()}
      >
        <i className="gallery-carousel__icon fas fas fa-chevron-circle-right" />
      </button>
      <AliceCarousel
        disableButtonsControls
        disableDotsControls
        infinite={true}
        ref={ref}
      >
        {children}
      </AliceCarousel>
    </div>
  );
}
