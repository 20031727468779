import React, { useState } from "react";
import HeroBanner from "../../components/HeroBanner";
import {
  Form,
  Input,
  TextArea,
  Submit,
  SubmitHandler,
} from "../../components/Form";
import ContactCards from "../../components/ContactCards";
import FormModal from "../../components/FormModal";

import "./Contact.scss";

export default function Contact() {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const onSubmit = ({ name, email, message }) => {
    SubmitHandler({
      text: `Name: ${name}\nEmail: ${email}\nMessage: ${message}`,
      subject: "Contact Us",
      setError: setError,
      setLoading: setLoading,
    });
    setShowModal(true);
  };

  return (
    <div className="contact">
      <FormModal
        showModal={showModal}
        setShowModal={setShowModal}
        text="Your message has been submited."
        error={error}
        setError={setError}
        loading={loading}
        setLoading={setLoading}
      />
      <HeroBanner
        title="Contact Us"
        body="We would love to hear from you!<br>We invite you to contact us about any questions or comments you may have."
      />
      <div className="field main">
        <div className="left_field">
          <div className="main_contact_box">
            <div className="contact_form" style={{ maxWidth: "50rem" }}>
              <h2 className="subheader primary-color short-underline mb-4 mx-auto">
                GET IN TOUCH
              </h2>
              <Form className="mb-3" onSubmit={onSubmit}>
                <Input
                  className="col-12"
                  name="name"
                  placeholder="Name *"
                  required={{
                    required: "Please Enter Your Name",
                    pattern: {
                      value: /^(?!\s*$).+/,
                      message: "Please Enter Your Name",
                    },
                  }}
                />
                <Input
                  className="col-12"
                  name="email"
                  placeholder="Email *"
                  required={{
                    required: "Please Enter A Valid Email Address",
                    pattern: {
                      // eslint-disable-next-line no-useless-escape
                      value:
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                      message: "Please Enter A Valid Email Address",
                    },
                  }}
                />
                <TextArea
                  className="col-12"
                  name="message"
                  placeholder="Comments / Questions"
                  required={{
                    required: "Please Enter Your Message",
                    maxLength: {
                      value: 500,
                      message: "Maximum length of 500 reached",
                    },
                    pattern: {
                      value: /^(?!\s*$).+/,
                      message: "Please Enter Your Message",
                    },
                  }}
                />
                <Submit className="col-12" position="middle" />
              </Form>
            </div>
          </div>
        </div>
        <div className="right_field">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.9332885565877!2d-79.68757768429285!3d43.58710586471964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b410360ea8a81%3A0x4e9fded9942b3166!2s5218%20Fallingbrook%20Dr%2C%20Mississauga%2C%20ON%20L5V%202C6!5e0!3m2!1sen!2sca!4v1657040491955!5m2!1sen!2sca"
            width="300"
            height="300"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>

      <ContactCards />
    </div>
  );
}
