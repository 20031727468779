import React from "react";
import PaymentForm from "../../components/PaymentForm";
import HeroBanner from "../../components/HeroBanner";

export default function index() {
  return (
    <div>
      <HeroBanner
        title="Donate"
        body="Sinai Foundation Canada is more than a local non-profit organization,
            we take further steps in trying to help communities across the globe. <br>
            We strive to improve the well being of the communities around us by providing skills that people can apply in their lives.
            We want to leave a lasting impact on those that we help so that one day they are able pay it foward 
            to another person in need. <br>
            A donation to Sinai Foundation Canada will be used to help those in need. 
            Our promise is that we will never stop helping those in need and we will never say 
            no to lending a helping hand. Please donate now to Sinai Foundation Canada to allow us to continue our services."
      />
      
      <div className="container-fluid main">
        <PaymentForm />
      </div>
    </div>
  );
}
