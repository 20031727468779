import React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
// import OurStory from "./OurStory";
import MissionAndPhilosophy from "./MissionAndPhilosophy";
import Leadership from "./Leadership";
import Partners from "./Partners";

// Route handler for all 3 about pages

export default function Index(props) {
  const match = useRouteMatch().url;

  return (
    <Switch>
      {/* <Route path={`${match}/our-story`} component={OurStory} /> */}
      <Route
        path={`${match}/mission-and-philosophy`}
        component={MissionAndPhilosophy}
      />
      <Route path={`${match}/leadership`} component={Leadership} />
      <Route path={`${match}/partners`} component={Partners} />
      <Redirect to={`${match}/our-story`} />
    </Switch>
  );
}
