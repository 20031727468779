import { React, useState } from "react";
import FlipBook from "../FlipBook/";

import "./BookletReader.scss";
import PreviewCard from "./PreviewCard";

// Booklet reader found on the mental health projects page

export default function BookletReader({ booklets }) {
  const [currBooklet, changeBooklet] = useState(0);
  // list of videos previews. Should be kept in order of the booklets
  return (
    <div className="booklet-container">
      <div className="previews row">
        {/* booklet preview cards */}
        {booklets.map((booklet, key) => {
          console.log(booklet.video);
          return (
            <div className="col-12 col-lg-4" key={key}>
              <PreviewCard key={key} active={key === currBooklet} cover={booklet.cover} title={booklet.title} onClick={() => changeBooklet(key)} />
              <span className="previews__subheader">
                <a className="external-link" href={booklet.video} target="_blank" rel="noreferrer">Find a mini video about the booklet here! {" "}
                  <i className="fab fa-youtube" />
                </a>
              </span>
            </div>
          );
        })}
      </div>
      {/* Video preview */}
      <FlipBook pdf={booklets[currBooklet].pdf} length={booklets[currBooklet].length} />
    </div>
  );
}