import React, { useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../Config/config";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import ErrorMessage from "../ErrorMessage/";
import "./Form.scss";

export function Form({ className = '', defaultValues, children, onSubmit }) {
  const methods = useForm({ defaultValues, shouldFocusError: true });

  useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      methods.reset({ defaultValues }, {
        keepErrors: true,
        keepDirty: true,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      });
    }
  }, [defaultValues, methods]);

  return (
    <FormProvider {...methods} >
      <form className={`form ${className}`} onSubmit={methods.handleSubmit(onSubmit)} >
        {children}
      </form>
    </FormProvider>
  );
};

// Typing inputs
export function Input({ className = '', name, placeholder, required = false, type = "text" }) {
  const { register, formState: { errors, submitCount } } = useFormContext();
  return (
    <div className={`form__field ${className}`}>
      <input
        className={`form-control form__input ${errors[name] ? "form__input--invalid" : submitCount ? "form__input--valid" : ''}`}
        type={type}
        name={name}
        placeholder=" "
        id={name}
        maxLength={required.maxLength !== undefined ? required.maxLength : ""}
        defaultValue=""
        aria-label={placeholder}
        {...register(name, required)}
      />
      <label className="form__placeholder" htmlFor={name}>
        {placeholder}
      </label>
      {errors[name] && <ErrorMessage msg={errors[name].message} />}
    </div>
  );
};

// Textarea alternative
export function TextArea({ className = '', name, placeholder, required = false }) {
  const { register, formState: { errors, submitCount } } = useFormContext();
  return (
    <div className={`form__field form__field--big ${className}`}>
      <textarea
        className={`form-control form__input ${errors[name] ? "form__input--invalid" : submitCount ? "form__input--valid" : ''}`}
        rows="4"
        name={name}
        placeholder=" "
        id={name}
        defaultValue=""
        aria-label={placeholder}
        {...register(name, required)}
      />
      <label className="form__placeholder" htmlFor={name}>
        {placeholder}
      </label>
      {errors[name] && <ErrorMessage msg={errors[name].message} />}
    </div>
  );
};

// Selection lists
export function Select({ className = '', defaultOption, name, placeholder, required = false, options, onChange }) {
  const { register, watch, formState: { errors, submitCount } } = useFormContext();

  const selectValue = watch(name);

  return (
    <div className={`form__field ${className}`}>
      <select
        className={`form-select form__select ${selectValue ? 'form__select--selected' : ''} ${errors[name] ? "form__select--invalid" : submitCount ? "form__select--valid" : ''}`}
        name={name}
        onChange={onChange}
        id={name}
        defaultValue=""
        aria-label={placeholder}
        {...register(name, required)}
      >
        <option disabled value="">
          {defaultOption}
        </option>
        {options.map((value) => (
          <option value={value} key={value}>{value}</option>
        ))}
      </select>
      <label className={`form__placeholder `} htmlFor={name}>
        {placeholder}
      </label>
      {errors[name] && <ErrorMessage msg={errors[name].message} />}
    </div>
  );
};

// Checkboxes
export function CheckBox({ className = '', name, placeholder, required = false }) {
  const { register, formState: { errors } } = useFormContext();
  return (
    <div className={`form__checkbox ${className}`}>
      <input
        className={``}
        type='checkbox'
        name={name}
        value={placeholder}
        {...register(name, required)}
      />
      <label className='form__checkbox-label' htmlFor={name} >{placeholder}</label>
    </div>
  );
};

// File selection
export function InputFile({ className = '', placeholder, name, required = false }) {
  const { register, formState: { errors } } = useFormContext();
  return (
    <div className={`form__file ${className}`}>
      <label htmlFor={name}>{placeholder}</label>
      <input type="file" id={name} name={name} {...register(name)} />
    </div>
  )
};

export function Submit({ position, className = '', value = 'SUBMIT' }) {
  return (
    <input
      className={`form__submit form__submit--${position} ${className}`}
      type="submit"
      value={value}
    />
  );
}

// Method that handles requests that require the sinai backend
export function SubmitHandler({ text, subject, info, setError, setLoading }) {
  setLoading(true);
  setError('');
  axios.post(`${API_URL}/email`, {
    from: 'info@sinaifoundation.ca',
    subject: subject,
    text: text,
    // info: info
  }).then(({ data }) => {
    // setResponse(data);
  }).catch(({ response }) => {
    if (response === undefined) setError(['503', 'Service Unavailable']);
    else setError([response.status, response.data]);
  }).finally(() => {
    setLoading(false);
  });
};

export function SubmitHandler2({ text, subject, setError, setLoading }) {
  setLoading(true);
  setError('');
  axios.post(`${API_URL}/email`, {
    from: 'info@sinaifoundation.ca',
    subject: subject,
    text: text,
    // info: info
  }).then(({ data }) => {
    //setResponse(data);
  }).catch(({ response }) => {
    if (response === undefined) setError(['503', 'Service Unavailable']);
    else setError([response.status, response.data]);
  }).finally(() => {
    setLoading(false);
  });
};

