import React from "react";
import SinaiLogo from "../../assets/SinaiLogo/sinai-logo-white.svg";
import "./Footer.scss";

export default function Footer() {
  return (
    <footer className="mt-auto" id="visible-footer">
      <div>
        {/* Left section (only logo) */}
        <div className="container pt-4">
          <div className="row">
            <div className="col-12 col-lg-4 col-md-4">
              <button
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                <img
                  src={SinaiLogo}
                  alt="Sinai Foundation Logo"
                  width={100}
                  height={100}
                />
              </button>
            </div>
            {/* Middle section */}
            <div className="col-12 col-lg-4 col-md-4">
              <h3>Connect with us</h3>
              <ul>
                <li>
                  <a
                    aria-label="Twitter"
                    name="Twitter"
                    title="Twitter"
                    href="https://twitter.com/SinaiCanada"
                    target="_blank"
                    rel="noreferrer"
                    className="button_social"
                  >
                    <i
                      className="fab fa-twitter fa-2x footer-social"
                      aria-hidden="true"
                    />{" "}
                  </a>
                </li>
                <li>
                  <a
                    aria-label="Facebook"
                    name="Facebook"
                    title="Facebook"
                    href="https://www.facebook.com/SinaiFoundationCanada"
                    target="_blank"
                    rel="noreferrer"
                    className="button_social"
                  >
                    <i
                      className="fab fa-facebook-f fa-2x footer-social"
                      aria-hidden="true"
                    />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="instagram"
                    name="instagram"
                    title="Instagram"
                    href="https://www.instagram.com/sinaifoundationca/"
                    target="_blank"
                    rel="noreferrer"
                    className="button_social"
                  >
                    <i
                      className="fab fa-instagram fa-2x footer-social"
                      aria-hidden="true"
                    />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="LinkedIn"
                    name="LinkedIn"
                    title="LinkedIn"
                    href="https://www.linkedin.com/in/sinaifoundationcanada-769495213/"
                    target="_blank"
                    rel="noreferrer"
                    className="button_social"
                  >
                    <i
                      className="fab fa-linkedin fa-2x footer-social"
                      aria-hidden="true"
                    />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="YouTube"
                    name="YouTube"
                    title="YouTube"
                    href="https://www.youtube.com/channel/UCYPqwExTszPfwFRhW4r_xzg"
                    target="_blank"
                    rel="noreferrer"
                    className="button_social"
                  >
                    <i
                      className="fab fa-youtube fa-2x footer-social"
                      aria-hidden="true"
                    />
                  </a>
                </li>
              </ul>
            </div>
            {/* Right section */}
            <div className="col-12 col-lg-4 col-md-4">
              <div className="ms-md-5 text-md-start text-center">
                <p className="footer-text">
                  <i className="fas fa-phone fa-1x" />
                  <span>
                    {" "}
                    <a href="tel:+18339051260">+1 (833) 905-1260</a>
                  </span>
                </p>
                <p className="footer-text">
                  <i className="fas fa-envelope fa-1x" />
                  <span>
                    {" "}
                    <a
                      aria-label="Email"
                      name="email"
                      href="mailto:info@sinaifoundation.ca"
                      target="_blank"
                      rel="noreferrer"
                    >
                      info@sinaifoundation.ca
                    </a>
                  </span>
                </p>
                <p className="footer-text">
                  <i className="fas fa-map-marker-alt fa-1x" />
                  <span>
                    {" "}
                    5218 Fallingbrook Drive
                    <br /> Mississauga ON L5V 2C6, Canada.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom d-flex justify-content-center align-items-center">
        <p className="copyright">
          &#169;2022 Sinai Foundation. All Rights Reserved
        </p>
      </div>
    </footer>
  );
}
