import React, { useRef } from 'react'
import CountUp from 'react-countup'
import useOnScreen from '../../../Hooks/useOnScreen';
import './Fact.scss';

export default function Fact({ factNumber, factTitle }) {
    const countUpRef = useRef()
    const isVisible = useOnScreen(countUpRef)

    return (
        <div>
            <p ref={countUpRef} className="fact-number mb-5 mt-4">
                <CountUp start={0} end={factNumber} redraw={isVisible} />
            </p>
            <p className="fact-title">{factTitle}</p>
        </div>
    )
}
