import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import './SlideCard.scss';

// Cards found on the our story page that move around when hovered. Found near the bottom of the page

export default function SlideCard({ img, body, link }) {
  const ref = useRef();

  const moveHandler = (e) => {
    // Get dimensions of slide element
    const r = ref.current.getBoundingClientRect()

    // Set x and y values prop values based on center of slide element
    ref.current.style.setProperty('--x', e.clientX - (r.left + Math.floor(r.width / 2)))
    ref.current.style.setProperty('--y', e.clientY - (r.top + Math.floor(r.height / 2)))
  }

  const leaveHandler = () => {
    // Reset x and y prop values when no longer hovering
    ref.current.style.setProperty('--x', 0)
    ref.current.style.setProperty('--y', 0)
  }

  return (
    <div ref={ref} onMouseMove={moveHandler} onMouseLeave={leaveHandler} className="slide">
      <div className="slide__image-wrapper">
        <img className="slide__image" src={img} alt='slide-card-pic' />
      </div>
      <div className="slide__content">
        {body}
        <Link className="slide__btn" to={`/${link}`}>Learn More</Link>
      </div>
    </div>
  );
}
