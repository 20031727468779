import "./Subscribe.scss";
import React, { useState } from "react";
import { Form, SubmitHandler2 } from "../Form/Form";
import FormModal from "../FormModal/index";

export default function Subscribe() {
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const onSubmit = ({}) => {
    SubmitHandler2({
      email: email,
      setError: setError,
      setLoading: setLoading,
    });
    setShowModal(true);
  };
  return (
    <div className="subscribe-block">
      <FormModal
        showModal={showModal}
        setShowModal={setShowModal}
        text="Your email has been submited."
        error={error}
        setError={setError}
        loading={loading}
        setLoading={setLoading}
      />
      <div className="form_field">
        <Form className="mb-3" onSubmit={onSubmit}>
          <label htmlFor="email">Enter your Email to Subscribe</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            className="subscribe-email"
          />
          <input type="submit" value="Submit" className="subscribe-submit px-3" />
        </Form>
      </div>
    </div>
  );
}
