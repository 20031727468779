import React from 'react';
import './PostCard.scss';
import { Link } from "react-router-dom";

// Small cards on homepgae that expand on hover

export default function PostCard({ title, body, img, link }) {
    return (
        <div className="post-card">
        <a className='post-card__link' href={link} target="_blank" rel="noreferrer">
        <article >
            
            <img src={img} />
                
                <h4 className="post-card__title">{title}</h4>
            <p className="post-card__body">{body}
            </p>
            
        </article>
            </a>
        </div>
    );
}
