import React from "react";
import "./OurMission.scss";

// mission section on home page, adjacent to commitment section

export default function OurMission() {
  return (
    <div className="mission">
      <h2 className="pt-4 pb-4 mission-title fw-bold">Our Commitment</h2>
      <p className="py-1">
        At Sinai Foundation Canada, our unwavering commitment is to drive
        impactful change through sustainable initiatives that empower
        communities locally and globally. With a dedicated focus on education,
        healthcare, and environmental stewardship, we collaborate with esteemed
        partners to foster innovation and create lasting social impact. Discover
        how our initiatives, from empowering youth through education to
        promoting eco-friendly practices, are shaping a brighter, more
        sustainable future for all
      </p>
    </div>
  );
}
