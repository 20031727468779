import React from "react";
import ResourceCard from "../ResourceCard";

// Resources section on the mental health project page

export default function OtherRegionsFoodbank() {
  return (
    // Resource cards are from the ResourceCard folder
    <section className="row row-cols-xxl-3 row-cols-xl-2 row-cols-md-1 row-cols-sm-2 row-cols-1 g-4">
      <div className="col">
        <ResourceCard
          title="Burlington Food Bank"
          titleLink="http://www.burlingtonfoodbank.ca/"
          content={[
            <>
              Committed to feeding the hungry in the community through food
              distribution.
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Daily Bread Food Bank"
          titleLink="https://www.dailybread.ca/"
          content={[
            <>
              Distributes fresh and shelf-stable food every day to their member
              agency networks.
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Georgetown Bread Basket"
          titleLink="hhttp://georgetownbreadbasket.ca/"
          content={[
            <>
              Provides food-oriented services to individuals primarily residing
              in Georgetown
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Islamic Social Services and Resources Association"
          titleLink="http://www.issra.ca/"
          content={[
            <>
              Serves individuals, families and communities who are vulnerable,
              in distress and lack access to other services because of obstacles
              of culture, language, race, poverty, gender, or other barriers.
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Muslim Welfare Centre"
          titleLink="https://www.muslimwelfarecentre.com/contact-us/office-locations/"
          content={[
            <>
              Fight against poverty and homelessness and are focused on
              providing food, health care services, culturally-sensitive shelter
              for women, their children and families in distress, and healthy
              nutrition to our school children and seniors.
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Oakville Fareshare Food Bank"
          titleLink="http://www.oakvillefoodbank.com/"
          content={[
            <>
              Focuses on providing food to all individuals, mainly targeting
              children.
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Safetynet Children And Youth Charities"
          titleLink="http://www.safetynetservices.ca/"
          content={[
            <>
              Provides services including food distribution to children and
              youth.
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Stop Community Food Centre"
          titleLink="http://www.thestop.org/"
          content={[
            <>
              Connects low-income Torontonians to good food in spaces that are
              warm, dignified, and respectful.
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Syyidah Aysha Centre - Milton Halal Food Bank"
          titleLink="http://www.miltonhalalfoodbank.com/"
          content={[
            <>
              Provides Halal food options to individuals primarily residing in
              Milton.
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="The Food Bank of York Region"
          titleLink="https://www.fbyr.ca/"
          content={[
            <>
              Serves as a food distribution hub and the only food access program
              in York Region with an expansive capacity to collect, warehouse
              and deliver refrigerated and frozen products safely.
            </>,
          ]}
        />
      </div>
    </section>
  );
}
