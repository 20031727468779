import axios from "axios";
import { useState } from "react";
import { API_URL } from "../../Config/config";
import { Form, Input, Select, Submit } from "../Form";
import FormModal from "../FormModal";
import "./PaymentForm.scss";

export default function PaymentForm() {
  // variables for storing payment information
  const [cardOwner, setCardOwner] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [cvv, setCVV] = useState("");
  const [amount, setAmount] = useState("");
  const [cardType, setCardType] = useState("");

  // states of the payment confrmation model
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState("");

  function clearInputs() {
    setCardOwner("");
    setCardNumber("");
    setExpiryMonth("");
    setExpiryYear("");
    setCVV("");
    setAmount("");
    setCardType("");
  }

  function getCardType(number) {
    const card = number;

    if (card[0] === "4") {
      setCardType("VISA");
    } else if (card[0] === "5") {
      setCardType("MC");
    } else if (card.slice(0, 2) === "37") {
      setCardType("AMEX");
    }
  }

  // function that handles changes to all input fields
  function handleChange(event) {
    event.preventDefault();
    const value = event.target.value;
    const id = event.target.id;

    if (id === "cardOwner") {
      setCardOwner(value);
    } else if (id === "cardNumber") {
      setCardNumber(value);
      getCardType(value);
    } else if (id === "expiryMonth") {
      setExpiryMonth(value);
    } else if (id === "expiryYear") {
      setExpiryYear(value);
    } else if (id === "cvv") {
      setCVV(value);
    } else if (id === "amount") {
      setAmount(value);
    }
  }

  // function that is called once the user finishes their donation
  async function onSubmit({
    name,
    email,
    phone,
    address,
    city,
    postalCode,
    country,
    comment,
  }) {
    setLoading(true);
    setError("");

    const body = {
      name,
      email,
      phone,
      address,
      country,
      city,
      postalCode,
      comment,
      cardOwner,
      cardNumber,
      cardType,
      expiryMonth,
      expiryYear,
      cvv,
      amount,
    };

    setShowModal(true);
    await axios
      .post(`${API_URL}/pay`, body, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => {
        setResponseData(data);
      })
      .catch(({ response }) => {
        console.log(`ERROR: ${response}`);
        if (response === undefined) setError(["503", "Service Unavailable"]);
        else setError([response.status, response.data]);
      })
      .finally(() => {
        setLoading(false);
      });
    clearInputs();
  }

  // function to dynamically render the next 10 years for a card's expiration year
  function renderYears() {
    let years = [];
    years.push("Year");
    for (
      let i = new Date().getFullYear() % 100;
      i < (new Date().getFullYear() % 100) + 10;
      i++
    ) {
      years.push(i);
    }
    return years.map((year, key) => (
      <option
        value={year === "Year" ? "" : year}
        disabled={year === "Year"}
        key={key}
      >
        {year}
      </option>
    ));
  }

  // this function renders the options for the left side drop down. It will be reasons for donation on the donate page and membership type on the join us page
  function renderLeftSideSelectMenu() {
    if (window.location.href.includes("donate")) {
      // donation page
      return (
        <Select
          className="col-12"
          name="comment"
          placeholder="Reason for donation"
          defaultOption=""
          options={[
            "Food Programs For Those in Need",
            "Poverty & Homeless Support",
            "Heath & Safety Support",
            "New Community Projects",
            "Seniors Programs",
            "Other",
          ]}
        />
      );
    } else if (window.location.href.includes("join-us/member")) {
      // membership page
      return (
        <Select
          className="col-12"
          name="comment"
          placeholder="Membership Type"
          defaultOption=""
          options={["Associate Member", "GBM Member", "Organization"]}
        />
      );
    }
  }

  function renderPaymentValues() {
    if (window.location.href.includes("donate")) {
      // donation page
      return [
        <option disabled value="">
          Select Amount
        </option>,
        <option value="2000">$20.00</option>,
        <option value="5000">$50.00</option>,
        <option value="10000">$100.00</option>,
        <option value="20000">$200.00</option>,
      ];
    } else if (window.location.href.includes("join-us/member")) {
      // membership page
      return [
        <option disabled value="">
          Select Amount
        </option>,
        <option value="2000">$20.00</option>,
        <option value="6000">$60.00</option>,
        <option value="10000">$100.00</option>,
      ];
    }
  }

  return (
    <div className="mt-2 container payment-form">
      <FormModal
        showModal={showModal}
        setShowModal={setShowModal}
        text={
          responseData
            ? `Your payment was successful ref#: ${responseData}`
            : ""
        }
        error={error}
        setError={setError}
        loading={loading}
        setLoading={setLoading}
      />
      <div
        className="row default-shadow bg-light p-3"
        style={{ borderRadius: "1rem" }}
      >
        {/****** Left side (Personal information) ******/}
        <div className="col">
          <Form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-lg-6 col-12 ps-3 pe-3 mt-3">
                <h3>Personal Information</h3>
                <h6>Please enter your personal Information below</h6>
                <div className="mt-4">
                  <Input
                    className="col-12"
                    name="name"
                    placeholder="Name *"
                    required={{
                      required: "Please Enter Your Name",
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Please Enter Your Name",
                      },
                    }}
                  />
                  <Input
                    className="col-12"
                    name="email"
                    placeholder="Email *"
                    required={{
                      required: "Please Enter A Valid Email Address",
                      pattern: {
                        // eslint-disable-next-line no-useless-escape
                        value:
                          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                        message: "Please Enter A Valid Email Address",
                      },
                    }}
                  />
                  <Input
                    className="col-12"
                    type="tel"
                    name="phone"
                    placeholder="Phone Number *"
                    required={{
                      required: "Please Enter A Valid Phone Number",
                      maxLength: 10,
                      pattern: {
                        value:
                          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                        message: "Please Enter A Valid Phone Number",
                      },
                    }}
                  />
                  <Input
                    className="col-12"
                    name="address"
                    placeholder="Address"
                  />
                  <div className="d-flex flex-wrap">
                    <Input
                      className="col-12 col-md"
                      name="country"
                      placeholder="Country"
                    />
                    <Input
                      className="col-12 col-md donate__middle"
                      name="city"
                      placeholder="City"
                    />
                    <Input
                      className="col-12 col-md"
                      name="postalCode"
                      required={{
                        maxLength: 7,
                      }}
                      placeholder="Postal Code"
                    />
                  </div>
                  {/* Select menu that changes depending on the current page */}
                  {renderLeftSideSelectMenu()}
                </div>
              </div>
              {/****** Right side (Payment information) ******/}
              <div className="col-lg-6 col-12 ps-3 pe-3 mt-3">
                <h3>Payment Information</h3>
                <h6>
                  Please enter the details of the card you would like to use:
                </h6>
                <div
                  className="default-shadow mt-4"
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #E9ECEF",
                    borderRadius: "10px",
                    padding: "2rem",
                  }}
                >
                  <div className="row">
                    <div className="col" dir="rtl">
                      <div className="input-group-append">
                        {" "}
                        <span className="text-muted">
                          {" "}
                          <i className="fab fa-cc-visa mx-1" />{" "}
                          <i className="fab fa-cc-mastercard mx-1" />{" "}
                          <i className="fab fa-cc-amex mx-1" />{" "}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="username">
                          <h6>Cardholder Name</h6>
                        </label>
                        <input
                          type="text"
                          id="cardOwner"
                          name="username"
                          value={cardOwner}
                          onChange={handleChange}
                          required
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="cardNumber">
                          <h6>Card Number</h6>
                        </label>
                        <div className="input-group">
                          <input
                            id="cardNumber"
                            name="cardNumber"
                            placeholder="#### #### #### ####"
                            maxLength={16}
                            style={{
                              border: "0px",
                              backgroundColor: "transparent",
                              borderBottom: "1px solid gray",
                              borderRadius: "0px",
                            }}
                            value={cardNumber}
                            onChange={handleChange}
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 mb-4">
                      <div className="form-group">
                        {" "}
                        <label>
                          <span className="hidden-xs">
                            <h6>Expiration Date</h6>
                          </span>
                        </label>
                        <div className="input-group">
                          <select
                            onChange={handleChange}
                            className="form-select"
                            id="expiryMonth"
                            value={expiryMonth}
                          >
                            {/* Note: the payment API will only use months formatted as 01,02,03,etc. and not 1,2,3 */}
                            <option value="" disabled>
                              Month
                            </option>
                            {[
                              "01",
                              "02",
                              "03",
                              "04",
                              "05",
                              "06",
                              "07",
                              "08",
                              "09",
                              "10",
                              "11",
                              "12",
                            ].map((month, key) => (
                              <option value={month} key={key}>
                                {month}
                              </option>
                            ))}
                          </select>
                          <select
                            onChange={handleChange}
                            className="form-select"
                            id="expiryYear"
                            value={expiryYear}
                          >
                            {renderYears()}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group mb-4">
                        {" "}
                        <label
                          data-toggle="tooltip"
                          title="Three digit CV code on the back of your card"
                        >
                          <h6 className="d-flex align-items-baseline">
                            CVV <i className="fa fa-question-circle ms-1" />
                          </h6>
                        </label>{" "}
                        <input
                          id="cvv"
                          placeholder="***"
                          maxLength={3}
                          type="text"
                          value={cvv}
                          onChange={handleChange}
                          required
                          className="form-control"
                        />{" "}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group mb-4">
                        {" "}
                        <label htmlFor="amount">
                          <span className="hidden-xs">
                            <h6>Amount (CAD)</h6>
                          </span>
                        </label>
                        <select
                          id="amount"
                          onChange={handleChange}
                          required
                          value={amount}
                          className="form-select"
                        >
                          {renderPaymentValues()}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-4">
              <Submit value="DONATE NOW" position="middle" />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
