import React, { useRef } from "react";
import useOnScreen from "../../Hooks/useOnScreen";
import "./BoardMember.scss";

import logo from "../../assets/SinaiLogo/sinai-logo.svg";

export default function BoardMember({
  memberName,
  memberTitle,
  memberInfo,
  // default image is the Sinai Foundation Logo if there is not available image for the member
  memberImg = logo,
}) {
  const ref = useRef(null);
  return (
    <div className={`board-member-card ${useOnScreen(ref) ? "fade-in" : ""} col-xl-4 col-12 col-lg-6 mb-3 mt-3`}>
      <img
        src={memberImg}
        className={`board-member-img${memberImg === logo ? "__filler" : ""}`}
        alt={memberName}
      />
      <div className="board-member-content" ref={ref}>
        <h5 className="board-member-name">{memberName}</h5>
        <h5 className="board-member-title">{memberTitle}</h5>
        <p className="board-member-info">{memberInfo}</p>
      </div>
    </div>
  );
}
