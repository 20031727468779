import React, { useEffect } from "react";
import ContentLoader from "react-content-loader";
import "./FormModal.scss";

// popup that appears when forms are submitted, confirming that the form was successfully submitted or denied/failed

export default function FormModal({
  setShowModal,
  showModal,
  text,
  error,
  loading,
}) {
  useEffect(() => {
    if (showModal) {
      document.body.classList.add("overflow-hidden");
    } else document.body.classList.remove("overflow-hidden");
  }, [showModal]);

  return (
    <div className={`form-modal ${showModal ? "form-modal--show" : ""}`}>
      <div className="form-modal__main ">
        {loading ? (
          // display when request is being processed
          <ContentLoader
            style={{ width: "100%", height: "20rem", padding: "1rem" }}
            speed={2}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <circle cx="50%" cy="20%" r="12%" />
            <rect x="0%" y="40%" rx="5" ry="5" width="100%" height="20%" />
            <rect x="0%" y="65%" rx="5" ry="5" width="100%" height="35%" />
          </ContentLoader>
        ) : (
          <>
            <div
              className={`form-modal__top ${
                error ? "form-modal__top--error" : ""
              }`}
            >
              {error ? (
                // display if error occurs
                <>
                  <i className="form-modal__icon far fa-times-circle" />
                  <h2 className="form-modal__title">Error {error[0]}</h2>
                </>
              ) : (
                // display if form is successfully submitted
                <>
                  <i className="form-modal__icon far fa-check-circle" />
                  <h2 className="form-modal__title">Thank You!</h2>
                </>
              )}
            </div>
            <div className="form-modal__body">
              {error ? (
                <p className="form-modal__text">{error[1]}</p>
              ) : (
                <p className="form-modal__text">{text}</p>
              )}
              <button
                type="button"
                name="close"
                className={`btn form-modal__button ${
                  error ? "form-modal__button--error" : ""
                }`}
                onClick={() => {
                    setShowModal(false)
                }}
              >
                OK
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
