import React from "react";
import HeroBanner from "../../../components/HeroBanner";
import "./Initiatives.scss";
import relief from "../../../assets/images/Unused/kids_peace_sign.jpg";
import redCross from "../../../assets/images/initiatives/redCross.jpg";
import lionsClub from "../../../assets/images/initiatives/lionsClub.jpg";
import rotaryClub from "../../../assets/images/initiatives/rotaryClub.jpg";

export default function index() {
  return (
    <div className="initiatives">
      <HeroBanner title="Initiatives" />

      <div className="main-body">
        <div className="mt-4 main container-fluid">
          <hr />
          <h3> Partnerships for Progress</h3>
          <h5>Our Collaborations in Canada</h5>
          <p>
            Sinai Foundation Canada has established impactful partnerships with
            esteemed organizations such as Red Cross Canada, Service Canada,
            Sheridan College, and Al-Mawarid Institute to drive transformative
            initiatives. These initiatives include providing aid to seniors and
            single parents, as well as developing programs and services to
            address social isolation and enhance mental health. This
            collaborative effort aims to make a significant impact on community
            well-being and support sustainable social initiatives.
          </p>

          <img className="rcross-img" src={redCross} alt="Red Cross" />
          <br></br>

          <h3>Our Commitment to Global Impact</h3>
          <p>
            Sinai Foundation Canada values the power of collaboration. By
            partnering with organizations renowned for their dedication to
            humanitarian causes and community development, we leverage
            collective expertise and resources to address pressing global
            challenges. Whether it's providing humanitarian aid in
            crisis-stricken regions or supporting sustainable development
            initiatives, our partnerships are instrumental in driving positive
            change.
            <br />
            At Sinai Foundation Canada, we are dedicated to making a lasting
            impact through strategic partnerships with esteemed international
            organizations. Through collaborations with the United Nations,
            Rotary Club, Lions Club, and INARA, we have embarked on
            transformative projects that deliver essential aid and promote
            sustainability worldwide.
          </p>

          <img className="lclub-img" src={lionsClub} alt="Lions Club" />

          <h5>Delivering Essential Aid</h5>
          <p>
            Through our collaborations, we deliver crucial aid where it's needed
            most. From disaster relief efforts supported by the United Nations
            to community development projects championed by local Rotary and
            Lions Clubs, Sinai Foundation Canada ensures that resources are
            efficiently deployed to benefit those facing adversity.
          </p>

          <img className="rclub-img" src={relief} alt="Rotary Club" />

          <h5>Promoting Sustainability</h5>
          <p>
            We believe in sustainable development as a cornerstone of our
            mission. Working with partners, we engage in projects that not only
            provide immediate relief but also foster long-term resilience in
            communities. By promoting eco-friendly practices and supporting
            initiatives that enhance local infrastructure and livelihoods, we
            aim to create a sustainable future for generations to come.
          </p>
        </div>
      </div>
    </div>
  );
}
