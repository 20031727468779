import React from "react";
import ResourceCard from "../ResourceCard/";

// Resources section on the mental health project page

export default function MentalHealthResources() {
  return (
    // Resource cards are from the ResourceCard folder
    <section className="row row-cols-xxl-3 row-cols-xl-2 row-cols-md-1 row-cols-sm-2 row-cols-1 g-4">
      <div className="col">
        <ResourceCard
          title="ConnexOntario"
          titleLink="https://www.connexontario.ca/en-ca/"
          content={[
            <>
              Call toll free at <br /> 1-866-531-2600
            </>,
            <>Free 24/7 access to healthcare services</>,
            <>
              Access their online chat room{" "}
              <a
                href="https://www.connexontario.ca/Chat"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="211 Ontario"
          titleLink="https://211ontario.ca/"
          content={[
            <>
              Call toll free at <br />
              1-877-330-3213 or dial 211
            </>,
            <>
              This provincial hotline provides information on programs and
              social services in your community ranging including food, family
              services, and homelessness.
            </>,
            <>
              Access their online chat room{" "}
              <a
                href="https://211ontario.ca/how-can-i-help/"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="BounceBack"
          titleLink="https://bouncebackontario.ca/"
          content={[
            <>
              Call toll free at <br /> 1-866-345-0224
            </>,
            <>
              A free skill-building program managed by the Canadian Mental
              Health Association
            </>,
            <>
              Is designed to help adults and young adults manage low mood, mild
              to moderate depression and anxiety, stress or worry
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Good2Talk"
          titleLink="https://good2talk.ca/"
          content={[
            <>
              Call toll free at <br /> 1-866-925-5454 (Ontario)
            </>,
            <>
              A line specifically for post-secondary students that provides
              mental health support services for those in crisis or in need of
              help
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Kids Help Phone"
          titleLink="https://kidshelpphone.ca/"
          content={[
            <>
              Call toll free at <br /> 1-800-668-6868
            </>,
            <>
              A line that allows for youth to connect and talk to other youth or
              talk to a counsellor about their problems
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Hope For Wellness Helpline"
          titleLink="https://hopeforwellness.ca/home.html"
          content={[
            <>
              Call toll free at <br /> 1-855-242-3310
            </>,
            <>
              Feel free to also their online chat room{" "}
              <a
                href="https://hopeforwellness.ca/home.html"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </>,
            <>
              The helpline provides mental health counselling and helps those
              that want to talk, are distressed, or are in any other crisis. The
              line is open 24/7
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Mental Health Resources in your community"
          titleLink="https://www.ontario.ca/page/mental-health-services"
          content={[
            <>
              Find mental health and addiction services on the Ontario
              provincial website
            </>,
            <>Locations for clinics and important hotlines can be found here</>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Distress and Crisis Ontario (DCO)"
          titleLink="https://www.dcontario.org/"
          content={[
            <>
              If you are in distress, crisis, or are experiencing suicidal
              thoughts, DCO can help
            </>,
          ]}
        />
      </div>
      <div className="col">
        <ResourceCard
          title="Mental Health in The Workplace"
          titleLink="https://www.ontario.ca/page/mental-health-workplace"
          content={[
            <>
              An official Ontario website containing information and resources
              regarding mental health in the workplace
            </>,
            <>
              Provides information on topics such as rights and
              responsibilities, health and safety, and insurance
            </>,
          ]}
        />
      </div>
    </section>
  );
}
