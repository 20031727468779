import React from "react";
import GoalsCard from "./GoalsCard/GoalsCard";

import povertyImage from "../../assets/svg/poverty.png";
import hungerImage from "../../assets/svg/hunger.png";
import healthImage from "../../assets/svg/health.png";
import workImage from "../../assets/svg/work.png";
import eduImage from "../../assets/svg/education.png";
import ineqImage from "../../assets/svg/inequalities.png";

import "./Goals.scss";

export default function Goals() {
  return (
    <div className="our-goal">
      <GoalsCard
        className="goal full-image-goal"
        img={
          <img
            src={eduImage}
            alt="Quality Education"
            className="goals-card__full-img"
          />
        }
        fullImage
      />

      <GoalsCard
        className="goal full-image-goal"
        img={
          <img
            src={workImage}
            alt="Decent Work"
            className="goals-card__full-img"
          />
        }
        fullImage
      />

      <GoalsCard
        className="goal full-image-goal"
        img={
          <img
            src={healthImage}
            alt="Health"
            className="goals-card__full-img"
          />
        }
        fullImage
      />

      <GoalsCard
        className="goal full-image-goal"
        img={
          <img
            src={ineqImage}
            alt="Reduced Inequalities"
            className="goals-card__full-img"
          />
        }
        fullImage
      />

      {/* <GoalsCard
              className="goal full-image-goal"
              img={
                  <img
                      src={povertyImage}
                      alt="No Poverty"
                      className="goals-card__full-img"
                  />
              }
              fullImage
          /> */}
      {/* <GoalsCard
              className="goal full-image-goal"
              img={
                  <img
                      src={hungerImage}
                      alt="No Hunger"
                      className="goals-card__full-img"
                  />
              }
              fullImage
          /> */}
    </div>
  );
}
