import React, { useRef } from "react";

import AliceCarousel from "react-alice-carousel";
import HomeCarouselSlide from "./HomeCarouselSlide";
import "./HomeCarousel.scss";

// background images for each slide of the carousel
import bg_1 from "../../assets/images/ipicture-orig.jpg";
import bg_2 from "../../assets/images/booklet_collage.jpg";

import Goals from "../Goals/Goals";

// Large carousel that is used on main page, and is the first thing you see when the page loads

export default function HomeCarousel() {
  const items = [
    <HomeCarouselSlide
      header="Sinai Foundation Canada"
      subheader="Let's Make The Change That Matters"
      link="/donate"
      linkHeader="Donate Today!"
      background={bg_1}
    />,
    <HomeCarouselSlide
      header="Check Out Our Mental Health Booklets!"
      link="/projects/Mental-Health-Project"
      linkHeader="Find Out More!"
      background={bg_2}
    />,
  ];

  const ref = useRef();

  return (
    <div className="home-carousel">
      {/* Buttons to control the carousel */}
      <button
        className="home-carousel__btn home-carousel__btn--left"
        onClick={() => ref.current.slidePrev()}
      >
        <i className="home-carousel__icon fas fas fa-chevron-circle-left" />
      </button>
      <button
        className="home-carousel__btn home-carousel__btn--right"
        onClick={() => ref.current.slideNext()}
      >
        <i className="home-carousel__icon fas fas fa-chevron-circle-right" />
      </button>
      <AliceCarousel
        disableButtonsControls
        disableDotsControls
        mouseTracking
        // autoPlay
        infinite
        autoPlayInterval={3500}
        animationDuration={750}
        items={items}
        ref={ref}
      />
      <Goals />
    </div>
  );
}
