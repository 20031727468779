import React from 'react';
import './PartnerCard.scss';

// partner cards found on home page partner carousel (near the bottom of the page) and on the parters subsection in the about section

// height parameter is taken in since not all images are equal height and need individual adjustment
export default function PartnerCard({ logo, link, altText, height }) {
  return (
    <div className="partner-card">
      <a className='partner-card__link' href={link} target="_blank" rel="noreferrer">
        <img className='partner-card__logo' src={logo} alt={altText} style={{ height: height }} />
      </a>
    </div>
  );
}
