import React from "react";
import "./ErrorMessage.scss";

// Error message used in input forms (ex. contact us form, class sign up form)

function ErrorMessage({ msg }) {
  return (
      <small className="error-msg">{msg}</small>
  );
}

export default ErrorMessage;
