import React from 'react';
import "./ResourceCard.scss"

// card specifically used for resources on mental health project page

export default function ResourceCard({ title, titleLink, content }) {
  return (
    <div className="resource-card card h-100">
      <div className="card-body">
        <a href={titleLink} target="_blank" rel="noreferrer" className="external-link card-title">
          {title}
        </a>
        <div className="info">
          {/* Have all content passed in rendered as a list */}
          <ul>
            {content.map((content, key) => {
              return <li key={key}>{content}</li>;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}