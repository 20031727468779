import React from "react";
import SlideCard from "../SlideCard/";
import img1 from "../../assets/images/home_img_3.jpg";
import img2 from "../../assets/images/picture-99.jpg";
import img3 from "../../assets/images/shutterstock-55.jpg";

import "./SlideCardSection.scss";

// container for slide cards on our story page

export default function SlideCardSection() {
  return (
    <section className="slide-card-section mt-5 mb-5">
      <div className="slide-card-section__container">
        <SlideCard
          img={img1}
          body={
            <>
              <p className="text-white slide__headline">
                Contributions &amp; <br /> Impact
              </p>
            </>
          }
          link="projects"
        />
        <SlideCard
          img={img2}
          body={
            <>
              <p className="text-white slide__headline">Staff &amp; <br /> Boards </p>
            </>
          }
          link="about/leadership"
        />
        <SlideCard
          img={img3}
          body={
            <>
              <p className="text-white slide__headline">
                Education Staff &amp; <br /> Courses
              </p>
            </>
          }
          link="our-services/classes"
        />
      </div>
    </section>
  );
}
