import React from "react";

import { Link, useParams, useRouteMatch } from "react-router-dom";
import HeroBanner from "../../../components/HeroBanner";
import SideNav from "../../../components/SideNav/";
import { descriptions } from "../descriptions";

// Each subpage of the projects section

export default function SubPage() {
  const { path } = useParams();
  const content = descriptions[path];
  return (
    <main>
      <HeroBanner
        title="Our Projects"
        body="Sinai Foundation is commited to providing help to those who need it, because helping others is the most blessed part of our life"
      />
      <div className="our-projects main">
        {/* Left side nav bar */}
        <SideNav>
          {Object.keys(descriptions).map((item) => (
            <Link
              className={`side-nav__link ${
                window.location.href.includes(item) ? "active" : ""
              }`}
              key={item}
              to={`${item}`}
            >
              {descriptions[item].title}
            </Link>
          ))}
        </SideNav>
        {/* Main body */}
        <div className="our-projects__body">
          <div className="our-projects__container">
            <h2 className="subheader primary-color our-projects__text">
              {content.title}
            </h2>
            <div className="our-projects__text mb-5 mt-2">{content.top}</div>
            <div className="our-projects__text mb-5 mt-2">{content.bottom}</div>
          </div>
        </div>
      </div>
    </main>
  );
}
