import React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import Communities from "./Communities";
import Classes from "./Classes";
import FoodPrograms from "./FoodPrograms";
import HealthAndSafetySupport from "./HealthAndSafetySupport";
import TechnicalServices from "../../components/TechnicalServices";
// Route handler for all services pages

function Index(props) {
  const match = useRouteMatch().url;
  return (
    <Switch>
      <Route path={`${match}/communities`} component={Communities} />
      <Route path={`${match}/classes`} component={Classes} />
      <Route path={`${match}/food-programs`} component={FoodPrograms} />
      <Route
        path={`${match}/health-and-safety-support`}
        component={HealthAndSafetySupport}
      />
      <Route path={`${match}/tech-services`} component={TechnicalServices} />

      <Redirect to={`${match}/classes`} />
    </Switch>
  );
}

export default Index;
